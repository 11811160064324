import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, debounce, getContext, put, select } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { logger } from "~/services/Logger"
import { actions as process, UpdateOrderSummary } from "../process"
import { carts, cartsSelector } from "../state/carts"
import { emptyException, orderSummariesActions } from "../state/orderSummaries"
import { pageSessionsSelector } from "../state/pageSessions"
import { RootState } from "../store"
import {
  selectOrderData,
  sendOrderToPrinticular,
  validateOrderForSummary,
} from "./helpers"
import { v4 as uuidv4 } from "uuid"
import { yieldToMain } from "~/services/webvitals/defer"

export type UpdateOrderSummaryReasonType =
  | "addressChanged"
  | "userInfoChanged"
  | "cartChange"
  | "printServiceChanged"
  | "fulfillmentChange"
  | "updateCouponCode"
  | "storeChanged"
  | "lineItemUpdated"
  | "other"
  | undefined

const UPDATE_ORDER_SUMMARY_DELAY = 600

export default function* watchProcessUpdateOrderSummary() {
  yield debounce(
    UPDATE_ORDER_SUMMARY_DELAY,
    process.updateOrderSummary.type,
    processUpdateOrderSummary
  )
}

function* processUpdateOrderSummary(
  action: PayloadAction<UpdateOrderSummary>
): SagaIterator<any> {
  const { reason, reasonType } = action.payload

  logger.log(`Updating order summary for reason: [${reason}]`)

  const orderData = yield call(selectOrderData)
  const {
    isValid: isOrderValid,
    isAddressComplete = false,
    errorMessage = "",
  } = validateOrderForSummary(orderData)

  // GET CURRENT PAGE

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )

  const { orderSummaryId, cartId } = pageSession

  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, cartId)
  )

  if (isOrderValid) {
    logger.log("Updating order summary")
    yield put(orderSummariesActions.setIsUpdating(orderSummaryId, true))

    yield call(yieldToMain)

    yield put(
      orderSummariesActions.setIsPendingAddress(
        orderSummaryId,
        !isAddressComplete
      )
    )

    yield call(yieldToMain)

    try {
      const printicularOrder = yield call(sendOrderToPrinticular, "dryRun")

      yield put(
        orderSummariesActions.setSummary(orderSummaryId, printicularOrder)
      )
      yield put(
        orderSummariesActions.setProcessingError(orderSummaryId, emptyException)
      )

      yield call(yieldToMain)

      //if the cart is changed after creating the order, then reset the order
      //to generate a new payment intent
      if (pageCart.paymentIntent?.clientSecret && reasonType === "cartChange") {
        yield put(
          carts.actions.updateOne({
            id: cartId,
            changes: {
              nonce: uuidv4(),
              paymentIntent: undefined,
            },
          })
        )
      }
    } catch (error: any) {
      logger.error(error)
      yield put(orderSummariesActions.setError(orderSummaryId, error))
    } finally {
      yield put(orderSummariesActions.setIsUpdating(orderSummaryId, false))
    }
  } else {
    logger.log(
      "Order is not complete enough to update the summary",
      errorMessage
    )
  }
}
