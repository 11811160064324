import { useEffect, useState } from "react"
import { isIOS, isMacOs, isMobileSafari, isSafari } from "react-device-detect"

export const useIsAppleDevice = () => {
  const [isAppleDevice, setIsAppleDevice] = useState(true)

  useEffect(() => {
    const isAppleDeviceValue = isIOS || isMobileSafari || isSafari || isMacOs
    setIsAppleDevice(isAppleDeviceValue)
  }, [])

  return isAppleDevice
}
