import { EntityId, PageEntity, PrintServiceEntity } from "@jackfruit/common"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { call, put, take, takeEvery } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductSwapMap } from "~/interfaces/entities/ProductSwapMap"
import {
  actions,
  ProductCompatibilityDialogPayload,
  UpdateFulfillementPayload,
} from "../process"
import { carts } from "../state/carts"
import { getCart } from "./cart"
import { getPage } from "./page"
import { getPageSession } from "./pageSession"
import { getPagePrintServiceForFulfillment } from "./printServices"
import {
  applyProductSwapMap,
  checkProductsCompatibility,
  computeProductSwapMapToTargetedFulfillment,
} from "./processProductsCompatibility"
import { saveFulfillmentDataToLocalStorage } from "./processRestoreDataFromLocalStorage"
import { v4 as uuidv4 } from "uuid"

export default function* watchProcessUpdateFulfillment() {
  yield takeEvery(actions.updateFulfillment.type, processUpdateFulfillment)
}

/**
 *
 * @param action
 */
function* processUpdateFulfillment(
  action: PayloadAction<UpdateFulfillementPayload>
): SagaIterator {
  const { onOpen, onClose, fulfillment, pageId, callback } = action.payload

  yield call(updateFulfillment, {
    onOpen,
    onClose,
    fulfillment,
    pageId,
    callback,
  })
}

export function* updateFulfillment(payload: UpdateFulfillementPayload) {
  const { onOpen, onClose, fulfillment, pageId, callback } = payload

  const productSwapMap: ProductSwapMap = yield call(
    computeProductSwapMapToTargetedFulfillment,
    { toFulfillment: fulfillment, pageId }
  )

  const {
    lineItemsToKeep,
    lineItemsToRemove,
  }: {
    lineItemsToKeep: EntityId[]
    lineItemsToRemove: EntityId[]
  } = yield call(checkProductsCompatibility, {
    productSwapMap,
  })

  let confirm = true
  if (lineItemsToRemove.length > 0) {
    onOpen?.({ lineItemsToKeep, lineItemsToRemove })
    const {
      payload: { choice },
    }: PayloadAction<ProductCompatibilityDialogPayload> = yield take(
      actions.productCompatibilityDialog.type
    )
    onClose?.()
    confirm = choice
  }

  if (confirm) {
    // swap product and set fulfillment
    const page: PageEntity = yield call(getPage, { pageId })
    const pageSession: PageSessionEntity = yield call(getPageSession, {
      pageSessionId: pageId,
    })
    const cart: CartEntity = yield call(getCart, pageSession.cartId)
    const printService: PrintServiceEntity = yield call(
      getPagePrintServiceForFulfillment,
      { fulfillment, pageId: page.id }
    )

    yield put(
      carts.actions.updateOne({
        id: cart.id,
        changes: {
          printServiceId: printService.id,
          fulfillment,
          couponCode: "",
          couponCodeTitle: "",
          couponCodeStatus: "pending",
        },
      })
    )
    //if the order is created, we need to reset the order
    if (cart?.paymentIntent) {
      yield put(
        carts.actions.updateOne({
          id: cart.id,
          changes: {
            nonce: uuidv4(),
            paymentIntent: undefined,
          },
        })
      )
    }

    yield call(applyProductSwapMap, { productSwapMap })
    yield call(saveFulfillmentDataToLocalStorage, { fulfillment })

    if (fulfillment === "delivery") {
      yield put(
        actions.updateOrderSummary({
          reason: "updated fulfillment",
          reasonType: "fulfillmentChange",
        })
      )
    }

    yield put(actions.updatedFulfillmentForPage(page.id)())
    callback?.()
  }
}
