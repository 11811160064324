import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import PasswordInput from "~/components/account/PasswordInput"
import ErrorPanel from "~/components/ErrorPanel"
import { useAuth } from "~/hooks/useAuth"
import { interactionResponse } from "~/services/webvitals/defer"

type FormData = {
  emailAddress: string
  password: string
}

const SignInForm = () => {
  const { t } = useTranslation()
  const { login, state: authState } = useAuth()
  const methods = useForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const onSubmit = async (data: FormData) => {
    await interactionResponse()
    login({
      login: data.emailAddress,
      password: data.password,
    })
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", margin: 0 }}
        noValidate
      >
        <Stack px={[2, 2, 2, 8]} py={5} spacing={7} width="100%">
          <Box textAlign="center">
            <Heading as="h4" m={0} size="lg">
              {t("components.header.SignIn.SignInForm.WelcomeBack")}
            </Heading>
            <Text m={0} fontSize="sm">
              {t("components.header.SignIn.SignInForm.SignInToYourAccount")}
            </Text>
          </Box>
          {authState.hasError && <ErrorPanel error={authState.error} />}
          <Stack spacing={3}>
            <FormControl id="sign-in-email" isInvalid={!!errors.emailAddress}>
              <Input
                data-testid="sign-in-email-input"
                type="email"
                placeholder={t(
                  "components.header.SignIn.SignInForm.EmailAddressPlaceholder"
                )}
                variant="filled"
                autoComplete="email"
                onClick={e => e.stopPropagation()}
                {...register("emailAddress", {
                  required: t<string>(
                    "components.header.SignIn.SignInForm.EmaiFieldIsMissingErrorMessage"
                  ),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t(
                      "components.header.SignIn.SignInForm.EmaiFieldIsInvalidErrorMessage"
                    ),
                  },
                })}
                onKeyDown={e => {
                  e.stopPropagation()
                }}
                isDisabled={authState.isAuthenticating}
              />
              {!!errors.emailAddress && (
                <FormErrorMessage>
                  {errors.emailAddress.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <PasswordInput isDisabled={authState.isAuthenticating} />
          </Stack>

          <Stack spacing={2}>
            <Button
              data-testid="sign-in-button"
              id="sign-in-button"
              colorScheme="primary"
              color="primary.text"
              width="100%"
              type="submit"
              isLoading={authState.isAuthenticating}
            >
              {t("components.header.SignIn.SignInForm.SignInButton")}
            </Button>
            <HStack
              justifyContent="space-between"
              spacing={[0, 20]}
              fontSize="sm"
              color="primary.500"
            >
              <Link id="sign-in-register-here" to="/register/">
                {t("components.header.SignIn.SignInForm.RegisterButton")}
              </Link>
              <Link id="sign-in-forgot-password" to="/forgot-password/">
                {t("components.header.SignIn.SignInForm.ForgotPasswordButton")}
              </Link>
            </HStack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default SignInForm
