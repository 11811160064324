import { Box } from "@chakra-ui/react"
import React from "react"
import { replaceShortCodes } from "~/services/replaceShortCodes"

interface Props {
  placement: "right" | "left"
  html: string
}

const HtmlAddon: React.FC<Props> = ({ placement = "right", html }) => {
  const marginValue = 1

  if (!html) {
    return null
  }
  const ml = placement === "right" ? marginValue : 0
  const mr = placement === "left" ? marginValue : 0

  const parsedHtml = replaceShortCodes(html, {})

  return (
    <Box
      as="span"
      display="inline-block"
      ml={ml}
      mr={mr}
      dangerouslySetInnerHTML={{ __html: parsedHtml }}
    />
  )
}

export default HtmlAddon
