import { useEffect, useState } from "react"
import { isAndroid } from "react-device-detect"

export const useIsAndroidDevice = () => {
  const [isAndroidDevice, setIsAndroidDevice] = useState(true)

  useEffect(() => {
    setIsAndroidDevice(isAndroid)
  }, [])

  return isAndroidDevice
}
