import { Alert, AlertIcon, AlertProps, Flex, Text } from "@chakra-ui/react"
import { kebabCase } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { ApiException } from "~/interfaces/entities/ApiException"

interface Props extends AlertProps {
  error: string
  showIcon?: boolean
  serverError?: ApiException
}

const ErrorPanel: React.FC<Props> = ({
  error,
  showIcon = true,
  serverError,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Alert
      id={`error-${serverError ? serverError.code : kebabCase(error)}`}
      status="error"
      mb={6}
      borderRadius="md"
      alignItems="flex-start"
      {...rest}
    >
      {showIcon && <AlertIcon />}
      <Flex direction="column">
        <Text>{serverError ? serverError.message : error}</Text>
        {serverError && (
          <Text as="i" color="gray.400" pt={2}>
            {t("components.ErrorPanel.Code", { code: serverError.code })}
          </Text>
        )}
      </Flex>
    </Alert>
  )
}

export default ErrorPanel
