import {
  EntityId,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { clamp, flatten } from "lodash"
import { call, put, select, take, takeEvery } from "redux-saga/effects"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import {
  PageType,
  ProductPageEntity,
  ProductPageEntityHydrated,
} from "~/interfaces/entities/ProductPage"
import { getPagesFromProduct } from "~/services/PrintServiceProductHelpers"
import { actions, UpdateMissingImagesForLineItem } from "../process"
import { imageRegionsSelectors } from "../state/imageRegions"
import { lineItems } from "../state/lineItems"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { productPagesSelectors } from "../state/productPages"
import { RootState } from "../store"
import { getLineItem, getLineItemPages } from "./lineItems"
import { createProductPage } from "./processLineItems"

export default function* watchUpdateMissingImagesForLineItem() {
  yield takeEvery(
    actions.updateMissingImagesForLineItem.type,
    processUpdateMissingImagesForLineItem
  )
}

function* processUpdateMissingImagesForLineItem(
  action: PayloadAction<UpdateMissingImagesForLineItem>
): SagaIterator<any> {
  const { lineItemId, files, onOpen, onClose } = action.payload

  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
  const productPages: ProductPageEntity[] = yield select((state: RootState) =>
    productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )
  const product: PrintServiceProductEntity = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, lineItem.productId)
  )

  const isPhotoBook = product.categoryName === "photo-book"

  yield call(() =>
    isPhotoBook
      ? uploadMissingImagesForPhotoBook({
          lineItemId,
          files,
          product,
          productPages,
          onOpen,
          onClose,
        })
      : uploadMissingImagesForNormalProducts({
          files,
          product,
          productPages,
          onOpen,
          onClose,
        })
  )
}

function* uploadMissingImagesForNormalProducts({
  files,
  product,
  productPages,
  onOpen,
  onClose,
}: {
  files: File[]
  product: PrintServiceProductEntity
  productPages: ProductPageEntity[]
  onOpen?: () => void
  onClose?: () => void
}) {
  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )

  const imageFit = product?.metaData?.web?.editor?.imageFit

  const allImageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )

  const allImageRegions: ImageRegionEntity[] = yield select(
    (state: RootState) =>
      imageRegionsSelectors.selectByIds(state, allImageRegionIds)
  )

  const imageRegionCount = allImageRegions.length
  const imageRegionWithImageCount = allImageRegions.filter(imageRegion =>
    Boolean(imageRegion.uploadId)
  ).length

  // images has already been applied to line item
  // but some pages are still empty
  const requireConfirmation =
    imageRegionWithImageCount > 0 &&
    imageRegionWithImageCount < imageRegionCount

  let confirm = true
  if (requireConfirmation) {
    onOpen?.()
    const {
      payload: { choice },
    } = yield take(actions.setUpdateMissingImagesDialogChoice.type)
    onClose?.()
    confirm = choice
  }

  // we got the user confirmation, let’s apply new images to empty pages
  if (confirm) {
    let currentFileIndex = 0
    for (let i = 0; i < allImageRegions.length; i++) {
      const currentImageRegion = allImageRegions[i]
      const hasUploadAttached = Boolean(currentImageRegion.uploadId)
      if (!hasUploadAttached) {
        const currentFile = files[currentFileIndex]
        yield put(
          actions.updateImageRegionImage({
            imageRegionId: currentImageRegion.id,
            file: currentFile,
            imageFit,
            isClampingEnabled,
          })
        )
        currentFileIndex++
        // no more files available, let’s break out
        if (currentFileIndex >= files.length) {
          break
        }
      }
    }
  }
}

//Photo book uploading image behaviour
function* uploadMissingImagesForPhotoBook({
  lineItemId,
  files,
  product,
  productPages,
  onOpen,
  onClose,
}: {
  lineItemId: EntityId
  files: File[]
  product: PrintServiceProductEntity
  productPages: ProductPageEntity[]
  onOpen?: (type?: string) => void
  onClose?: () => void
}) {
  const printServiceProductImages: PrintServiceProductImageEntity[] =
    yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        product.printServiceProductImages
      )
    )

  const { maxImages } = product
  const imageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )
  const imageRegions: ImageRegionEntity[] = yield select((state: RootState) =>
    imageRegionsSelectors.selectByIds(state, imageRegionIds)
  )

  const filesCount = files.length
  const imageRegionsCount = imageRegions.length
  const uploadedImagesCount = imageRegions.filter(imageRegion =>
    Boolean(imageRegion.uploadId)
  ).length

  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )
  const imageFit = product?.metaData?.web?.editor?.imageFit

  //image regions to add equals
  //number of uploading images + number of uploaded images - number of existing image regions
  const imageRegionAmountToAdd =
    filesCount + uploadedImagesCount - imageRegionsCount
  const imageRegionMaxAmountToAdd = maxImages - imageRegionsCount

  // images has already been applied to line item
  // but some pages are still empty
  const showExceedMaxImagesWarning =
    imageRegionAmountToAdd > imageRegionMaxAmountToAdd
  const requireConfirmation =
    uploadedImagesCount > 0 && uploadedImagesCount < imageRegionsCount

  let confirm = true
  if (showExceedMaxImagesWarning) {
    onOpen?.("warning")
    const {
      payload: { choice },
    } = yield take(actions.setUpdateMissingImagesDialogChoice.type)
    onClose?.()
    confirm = choice
  }

  if (requireConfirmation && !showExceedMaxImagesWarning) {
    onOpen?.()
    const {
      payload: { choice },
    } = yield take(actions.setUpdateMissingImagesDialogChoice.type)
    onClose?.()
    confirm = choice
  }

  // we got the user confirmation, let's apply new images to empty pages
  if (confirm) {
    const requiredProductPages = getPagesFromProduct({
      ...product,
      printServiceProductImages,
    })

    // there is always a first page
    const firstPage = requiredProductPages.find(
      page => page.pageType === PageType.Page
    )!

    const newImageRegionIds: EntityId[] = yield call(() =>
      addExtraSpreadsForPhotoBook(
        lineItemId,
        clamp(imageRegionAmountToAdd, 0, imageRegionMaxAmountToAdd),
        firstPage
      )
    )

    const allImageRegionIds = flatten([
      ...productPages.map(productPage => productPage.imageRegionIds),
      ...newImageRegionIds,
    ])

    const allImageRegions: ImageRegionEntity[] = yield select(
      (state: RootState) =>
        imageRegionsSelectors.selectByIds(state, allImageRegionIds)
    )
    const allImageRegionCount = allImageRegions.length

    let currentFileIndex = 0
    for (let i = 0; i < allImageRegionCount; i++) {
      const currentImageRegion = allImageRegions[i]
      const hasUploadAttached = Boolean(currentImageRegion.uploadId)
      if (!hasUploadAttached) {
        const currentFile = files[currentFileIndex]
        yield put(
          actions.updateImageRegionImage({
            imageRegionId: currentImageRegion.id,
            file: currentFile,
            imageFit,
            isClampingEnabled,
          })
        )

        currentFileIndex++
        if (currentFileIndex >= filesCount) {
          break
        }
      }
    }
  }
}

function* addExtraSpreadsForPhotoBook(
  lineItemId: EntityId,
  quantity: number,
  productPageFromProduct: ProductPageEntityHydrated
) {
  const { updateOne: updateLineItem } = lineItems.actions

  const pageQuantityToCreate = quantity % 2 === 0 ? quantity : quantity + 1

  const newImageRegionIds: EntityId[] = []
  const newProductPageIds: EntityId[] = []

  const { productPageIds }: LineItemEntity = yield call(() =>
    getLineItem(lineItemId)
  )

  const lineItemProductPages: ProductPageEntity[] = yield call(() =>
    getLineItemPages(lineItemId)
  )

  // find the id of the last normal page
  const normalPages = lineItemProductPages.filter(
    productPage => productPage.pageType === PageType.Page
  )

  // get the latest page id
  const lastNormalPageId = normalPages[normalPages.length - 1].id

  // we want to insert the new pages after the last normal page
  const targetPosition = productPageIds.indexOf(lastNormalPageId) + 1

  for (let i = 0; i < pageQuantityToCreate; i++) {
    const {
      pageId,
      imageRegionIds,
    }: { pageId: EntityId; imageRegionIds: EntityId[] } = yield call(() =>
      createProductPage(productPageFromProduct)
    )
    newImageRegionIds.push(...imageRegionIds)
    newProductPageIds.push(pageId)
  }

  yield put(
    updateLineItem({
      id: lineItemId,
      changes: {
        productPageIds: [
          ...productPageIds.slice(0, targetPosition),
          ...newProductPageIds,
          ...productPageIds.slice(targetPosition),
        ],
      },
    })
  )

  return newImageRegionIds
}
