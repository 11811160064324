import { replace } from "@wordpress/shortcode"
import React from "react"
import Carousel from "~/components/short-codes/carousel/Carousel"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

//!! Please note: do not use any chakra values in shortcode
// Usage:
//
//[carousel id=1 width="100%" height="400px" delay=0
//    carouselStyle='{"borderRadius": "5px", "mt": "6px", "mb": "7px", "color": "blue" }'
//    thumbnailStyle='{"width": "120px", "height": "120px", "borderRadius": "5px", "borderColor": "#f9f8f7", "borderFocusColor": "#aabca7"}'
//    circleStyle='{"size": "10px", "mx": "3px", "borderColor": "#f9f8f7", "borderFocusColor": "#aabca7"}'
//    thumbnailIndicatorSize='{"md": "80px", "lg": "100px", "xl": "120px"}'
//    mobileThumbnailType="circle" or "image"
//]
//   [image src="https://media.istockphoto.com/photos/various-fresh-ripe-pumpkins-as-background-picture-id1277767891" alt="image one" showThumbnails=0 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]
//   [image src="https://media.istockphoto.com/photos/asian-chinese-mid-adult-woman-helping-her-father-in-the-farm-picture-id1268636389" alt="image two"  showThumbnails=1 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]]
//   [image src="https://media.istockphoto.com/photos/autumn-leaves-falling-on-the-ground-in-a-park-picture-id1278765757" alt="image three"  showThumbnails=1 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]]
// [/carousel]
export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("carousel", html, args => {
    const {
      width = "300px",
      height = "200px",
      delay = 3000,
      carouselstyle = "{}",
      thumbnailstyle = "{}",
      circlestyle = "{}",
      thumbnailindicatorsize = "{}",
      mobilethumbnailtype = "circle",
    } = args.attrs.named
    const content = args.content ?? ""
    const images = imageParser(content)

    if (images.length === 0) {
      return `[carousel error="no images provided"]`
    }

    const thumbnailIndicatorSizeParsed = JSON.parse(thumbnailindicatorsize)
    const carouselStyleParsed = JSON.parse(carouselstyle)
    const circleStyleParsed = JSON.parse(circlestyle)
    const thumbnailStyleParsed = JSON.parse(thumbnailstyle)

    return renderShortCode({
      type: "carousel",
      args,
      component: (
        <Carousel
          width={width}
          height={height}
          delay={Number(delay)}
          images={images}
          carouselStyle={carouselStyleParsed}
          circleStyle={circleStyleParsed}
          thumbnailStyle={thumbnailStyleParsed}
          thumbnailIndicatorSize={thumbnailIndicatorSizeParsed}
          mobileThumbnailType={mobilethumbnailtype}
          config={state.appConfig!}
        />
      ),
    })
  })
}

const imageParser = (html: string = "") => {
  const all: any[] = []
  replace("image", html, args => {
    all.push(args.attrs.named)

    return ""
  })

  return all
}
