import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilValue } from "recoil"
import { useTextRegion } from "~/hooks/useTextRegion"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import {
  textRegionAlignFamily,
  textRegionColorFamily,
  textRegionFontFamily,
  textRegionSizeFamily,
  textRegionTextFamily,
} from "./atoms"
import SvgText from "./text/SvgText"
import { TextAnchor } from "./text/types"

export interface TextRegionPreviewProps {
  textRegionId: EntityId
}

const textAnchors = {
  left: "start",
  center: "middle",
  right: "end",
}

export const TextRegionPreview: React.FC<TextRegionPreviewProps> = ({
  textRegionId,
}) => {
  const { textRegion } = useTextRegion(textRegionId)
  const { window } = textRegion!

  const textAlign = useRecoilValue(textRegionAlignFamily(textRegionId))
  const fontFamily = useRecoilValue(textRegionFontFamily(textRegionId))
  const fontSize = useRecoilValue(textRegionSizeFamily(textRegionId))
  const color = useRecoilValue(textRegionColorFamily(textRegionId))
  const text = useRecoilValue(textRegionTextFamily(textRegionId))

  const { x: defaultX, y, width, height } = window
  let x = textAlign === "center" ? defaultX + width / 2 : defaultX
  if (textAlign === "right") {
    x = defaultX + width
  }

  const lineHeight = fontSize * 1.3
  const textAnchor = textAnchors[textAlign] as TextAnchor

  return (
    <SvgText
      x={x}
      y={y}
      width={width}
      height={height}
      textAnchor={textAnchor}
      verticalAnchor="start"
      lineHeight={`${lineHeight}px`}
      style={{
        fontFamily,
        lineHeight,
        fontSize,
        fill: color,
      }}
    >
      {text}
    </SvgText>
  )
}

export interface StaticTextRegionPreviewProps {
  textRegion: TextRegionEntity
}

export const StaticTextRegionPreview: React.FC<
  StaticTextRegionPreviewProps
> = ({ textRegion }) => {
  const window = textRegion!.window
  const textAlign = textRegion!.align
  const fontFamily = textRegion!.font
  const fontSize = textRegion!.size
  const color = textRegion!.color
  const text = textRegion!.text

  const { x: defaultX, y, width, height } = window
  let x = textAlign === "center" ? defaultX + width / 2 : defaultX
  if (textAlign === "right") {
    x = defaultX + width
  }

  const lineHeight = fontSize * 1.3
  const textAnchor = textAnchors[textAlign] as TextAnchor

  return (
    <SvgText
      x={x}
      y={y}
      width={width}
      height={height}
      textAnchor={textAnchor}
      verticalAnchor="start"
      lineHeight={`${lineHeight}px`}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fill={color}
    >
      {text}
    </SvgText>
  )
}
