import {
  Box,
  Button,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverContent,
  PopoverTrigger as OriginPopoverTrigger,
  Stack,
} from "@chakra-ui/react"
import { NavigationLinkEntityHydrated } from "@jackfruit/common"
import { Link as GatsbyLink, navigate } from "gatsby"
import React from "react"
import { FiChevronDown } from "react-icons/fi"
import { interactionResponse } from "~/services/webvitals/defer"
import HtmlAddon from "./HtmlAddon"
import MegaMenu from "./megamenu/MegaMenu"

interface NavigationLinkProps {
  color: string
  colorHover?: string
  fontSize: string
  link: NavigationLinkEntityHydrated
  isSubMenu?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  link,
  color,
  colorHover,
  fontSize,
  isSubMenu = false,
  ...rest
}) => {
  const {
    type,
    path,
    label,
    labelPrefixHtml,
    labelSuffixHtml,
    external,
    children,
    page,
    megamenu,
  } = link
  const fontWeight = 400

  switch (type) {
    case "link":
      return (
        <NavigationButton
          color={color}
          colorHover={colorHover}
          fontSize={fontSize}
          isExternal={external}
          path={`${path ?? "/"}`}
          isSubMenu={isSubMenu}
          {...rest}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "page":
      return (
        <NavigationButton
          color={color}
          colorHover={colorHover}
          fontSize={fontSize}
          isExternal={false}
          path={`/${page.path}`}
          isSubMenu={isSubMenu}
          {...rest}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "text":
      return (
        <Stack direction={"row"} spacing={4}>
          <Popover trigger={"click"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as={Button}
                p={4}
                color={color}
                backgroundColor="transparent"
                _hover={{
                  textDecoration: "underline",
                  color: colorHover ?? color,
                  backgroundColor: "transparent",
                }}
                fontWeight={fontWeight}
                fontSize={fontSize}
                lineHeight={"20px"}
              >
                {label}
                <Icon as={FiChevronDown} ml={2} />
              </Box>
            </PopoverTrigger>

            {children && (
              <PopoverContent
                bg="white"
                p={0}
                rounded={"md"}
                minW={"3xs"}
                width={"auto"}
                borderWidth={1}
                boxShadow="base"
                aria-label="menu-list"
              >
                <Stack mt={0} aria-label="menu-item-stack">
                  {children.map((child, index) => {
                    return (
                      <Box
                        key={index}
                        px={0}
                        py={2}
                        _hover={{
                          bg: "gray.100",
                        }}
                        my={0}
                        mt={"0px !important"}
                        mb={0}
                        aria-label="menu-item-box"
                      >
                        <NavigationLink
                          color={color}
                          colorHover={colorHover}
                          link={child}
                          fontSize={fontSize}
                          isSubMenu={true}
                          {...rest}
                        />
                      </Box>
                    )
                  })}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Stack>
      )
    case "megamenu":
      return (
        <MegaMenu
          megamenu={megamenu}
          label={label}
          color={color}
          colorHover={colorHover}
        />
      )
  }
}

interface NavigationButtonProps {
  children: React.ReactNode
  color: string
  colorHover?: string
  fontSize: string
  path: string
  isExternal: boolean
  isSubMenu?: boolean
}

interface NavigationButtonLinkProps {
  activeStyle?: { color?: string; textDecoration?: string }
  isExternal?: boolean
  rel?: string
  onClick?: (e?: any) => void
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  color,
  colorHover,
  fontSize,
  children,
  path,
  isExternal,
  isSubMenu = false,
  ...rest
}) => {
  // const theme = useTheme() // @Todo handle after cleaning this component up with better props
  const linkProps: NavigationButtonLinkProps = {}

  if (isExternal) {
    linkProps.isExternal = true
    linkProps.rel = "noopener"
    linkProps.onClick = () => window.open(path, "newWindow")
  } else {
    linkProps.activeStyle = {
      // color: theme.colors.primary["500"], // @Todo handle after cleaning this component up with better props
      textDecoration: "underline",
    }
    linkProps.onClick = async e => {
      e.preventDefault()
      await interactionResponse()
      navigate(path)
    }
  }

  const { px = 4, py, ...navigationBoxProps } = rest as any

  return (
    <Box
      as={isExternal ? ChakraLink : GatsbyLink}
      justifyContent="flex-start"
      fontWeight="normal"
      textAlign={isSubMenu ? "left" : "center"}
      lineHeight="shorter"
      to={path}
      fontSize={fontSize}
      color={color}
      px={px}
      py={isSubMenu ? 2 : py}
      width={isSubMenu ? "full" : "auto"}
      _hover={{ textDecoration: "underline", color: colorHover ?? color }}
      {...linkProps}
      {...navigationBoxProps}
    >
      {children}
    </Box>
  )
}

export default NavigationLink

// Temp fix TypeError for PopoverTrigger
// https://github.com/chakra-ui/chakra-ui/issues/5896
// Todo: remove after chakra-ui fix
export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OriginPopoverTrigger
