import {
  AppEntityHydrated,
  ClientEntity,
  PrinticularOrder,
} from "@jackfruit/common"
import { md5 } from "js-md5"
import React, { ReactNode } from "react"
import { createRoot } from "react-dom/client"
import { renderToString } from "react-dom/server"
import { parser as accordionParser } from "./shortCodeParsers/accordion"
import { parser as appButtonsParser } from "./shortCodeParsers/appButtons"
import { parser as badgeParser } from "./shortCodeParsers/badge"
import { parser as carouselParser } from "./shortCodeParsers/carousel"
import { parser as countrySelectParser } from "./shortCodeParsers/countrySelect"
import { parser as getStartedParser } from "./shortCodeParsers/getStarted"
import { parser as linkParser } from "./shortCodeParsers/link"
import { parser as navigationParser } from "./shortCodeParsers/navigation"
import { parser as orderParser } from "./shortCodeParsers/order"
import { parser as shareOnSocialParser } from "./shortCodeParsers/shareOnSocial"
import { parser as socialMediaButtonsParser } from "./shortCodeParsers/socialMediaButtons"
import { isBrowser } from "./Utils"

export type ShortCode =
  | "navigations"
  | "appButtons"
  | "orders"
  | "links"
  | "badges"
  | "getStarted"
  | "countrySelect"
  | "shareOnSocial"
  | "socialMediaButtons"
  | "carousel"
  | "accordion"

export const allShortCodes: ShortCode[] = [
  "navigations",
  "appButtons",
  "orders",
  "links",
  "badges",
  "getStarted",
  "countrySelect",
  "shareOnSocial",
  "socialMediaButtons",
  "carousel",
  "accordion",
]

export interface ShortCodeStateEntities {
  order?: PrinticularOrder
  client?: ClientEntity
  appConfig?: AppEntityHydrated
}

export type ShortCodeParser = (
  html: string,
  state: ShortCodeStateEntities
) => string

interface AllShortCodeParsers {
  [key: string]: ShortCodeParser
}

const shortCodeParsers: AllShortCodeParsers = {
  navigations: navigationParser,
  orders: orderParser,
  links: linkParser,
  badges: badgeParser,
  getStarted: getStartedParser,
  countrySelect: countrySelectParser,
  shareOnSocial: shareOnSocialParser,
  appButtons: appButtonsParser,
  socialMediaButtons: socialMediaButtonsParser,
  carousel: carouselParser,
  accordion: accordionParser,
}

export const replaceShortCodes = (
  textWithShortCode: string = "",
  state: ShortCodeStateEntities = {}
) => {
  const parsed = allShortCodes.reduce((someText, code) => {
    const parser = shortCodeParsers[code]

    const newText = someText ? parser(someText, state) : someText

    return newText
  }, textWithShortCode)

  return parsed
}

export const generateIdForShortCode = (type: string, args: string) => {
  const hash = md5(`${JSON.stringify(args)}-${type}`)
  const id = `${type}-${hash}`

  return id
}

// this is to cache the roots of the shortcodes
const cachedRoots: any = {}

interface RenderShortCodeOptions {
  type: string
  component: ReactNode
  args: any
}

// use this for dynamic shortcodes, any component that are not only static
// like the ones that has onClick, or any other dynamic behavior
export const renderShortCode = (options: RenderShortCodeOptions) => {
  const { component, args, type } = options
  const isDev = process.env.GATSBY_ACTIVE_ENV === "dev"

  // generate a uniq id based on all arguments and the type of the shortcode
  const elementId = generateIdForShortCode(type, args)

  if (isBrowser) {
    setTimeout(() => {
      const el = document.getElementById(elementId)
      if (el) {
        if (cachedRoots[elementId]) {
          cachedRoots[elementId].unmount()
        }
        const root = createRoot(el)
        cachedRoots[elementId] = root
        cachedRoots[elementId].render(component)
      }
    })
  }

  if (isDev) {
    // on dev mode, the component is not prerendered, but mounted on the client
    // when the page loads
    return renderToString(<div id={elementId} />)
  } else {
    // on prod mode, the component is prerendered and the html is returned
    // with the non hydrated version of the component (html only)
    return renderToString(<div id={elementId}>{component}</div>)
  }
}
