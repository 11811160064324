import { Link as ChakraLink, Text } from "@chakra-ui/react"
import { NavigationMegaMenuCellLinkEntityHydrated } from "@jackfruit/common"
import { Link as GatsbyLink } from "gatsby"
import React, { useState } from "react"
import { usePage } from "~/hooks/usePage"

interface Props {
  link: NavigationMegaMenuCellLinkEntityHydrated
  to?: string
}

const InternalLink: React.FC<Props> = ({ link, to = "" }) => {
  const { label } = link
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  return (
    <GatsbyLink
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={to}
      activeStyle={{
        textDecoration: "underline",
      }}
      style={{
        textDecoration: isHovering ? "underline" : "none",
      }}
    >
      <Text textDecoration={isHovering ? "underline" : "none"}>{label}</Text>
    </GatsbyLink>
  )
}

const ExternalLink: React.FC<Props> = ({ link, to = "" }) => {
  const { label } = link
  return (
    <ChakraLink
      id={`mega-menu-link-${link.id}`}
      href={to}
      isExternal={true}
      onClick={() => {
        window.open(to, "newWindow")
      }}
      _hover={{
        textDecoration: "underline",
      }}
    >
      <Text>{label}</Text>
    </ChakraLink>
  )
}

const MegaMenuCellLink: React.FC<Props> = ({ link }) => {
  const isPageLink = link.type === "page"
  const { page } = usePage(link.pageId)
  const to = isPageLink ? page?.path : link.path
  const { external: isExternal } = link

  if (isExternal) {
    return <ExternalLink link={link} to={to} />
  } else {
    return <InternalLink link={link} to={to} />
  }
}

export default MegaMenuCellLink
