import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  VStack,
  IconButton,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import { range } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { AiFillStar, AiOutlineStar, AiOutlineClose } from "react-icons/ai"
import { RemoveScroll } from "react-remove-scroll"

interface Props {
  appConfig: AppEntityHydrated
  onInstall: () => void
  onCloseBanner: () => void
  isAppleDevice: boolean
  slideIn: boolean
  transitionTime: number
}

const AppBannerFull: React.FC<Props> = ({
  appConfig,
  onInstall,
  onCloseBanner,
  isAppleDevice,
  slideIn,
  transitionTime,
}) => {
  const { faviconImage } = appConfig

  const { appName, rating } = isAppleDevice
    ? {
        appName: appConfig.iosStoreName,
        rating: appConfig.iosStoreRating,
      }
    : {
        appName: appConfig.androidStoreName,
        rating: appConfig.androidStoreRating,
      }

  const { t } = useTranslation()
  const isVisible =
    useBreakpointValue({
      base: true,
      lg: false,
    }) ?? true

  return (
    <RemoveScroll removeScrollBar={false} enabled={isVisible}>
      <Flex
        id="install-mobile-app-banner"
        flexDirection="column"
        bg={slideIn ? "blackAlpha.400" : "transparent"}
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={11}
        transition={`${transitionTime}ms all`}
        overflow="hidden"
      >
        <Box
          id="p-mobile-banner-dismiss-action" // GTM: click on mobile banner dismiss action
          flexGrow={1}
          width="full"
          onClick={onCloseBanner}
        />
        <VStack
          bg="white"
          width="full"
          p={4}
          alignItems="center"
          transition={`${transitionTime}ms all`}
          marginBottom={slideIn ? 0 : "-50vh"}
        >
          <Flex flexWrap="wrap" maxW="550px" w="full" position="relative">
            <IconButton
              id="p-mobile-banner-dismiss-button"
              bg={"white"}
              boxShadow="md"
              aria-label="Call Sage"
              fontSize="20px"
              icon={<AiOutlineClose />}
              onClick={onCloseBanner}
              position="absolute"
              top={-8}
              right={-1}
              borderRadius="full"
              size={"sm"}
            />
            <HStack mr={9}>
              <Image
                className="p-mobile-banner-app-icon" // GTM: click on mobile banner app icon
                onClick={onInstall}
                src={faviconImage.path}
                p={2}
                my={2}
                boxSize={20}
                htmlWidth={128}
                htmlHeight={128}
                bg="white"
                borderRadius="base"
                ignoreFallback={true}
                boxShadow="md"
              />
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontWeight="bold" fontSize={"md"}>
                  {appName}
                </Text>
                <Text color="gray.600" fontSize={"sm"} m={0}>
                  {t("components.AppBanner.Full.Description")}
                </Text>
                <HStack spacing={0}>
                  {range(5).map(i => (
                    <Icon
                      key={i}
                      as={i + 0.5 <= rating ? AiFillStar : AiOutlineStar}
                      boxSize={4}
                      color="orange.200"
                    />
                  ))}
                  <Text color="gray.400" fontSize={"sm"} my={0} pl={1} mr={0}>
                    {" "}
                    {t("components.AppBanner.Full.Author")}
                  </Text>
                </HStack>
              </VStack>
            </HStack>

            <ButtonGroup
              display="flex"
              flexDirection="column"
              justifyContent="center"
              spacing={0}
              flexGrow={1}
            >
              <Button
                bg="primary.500"
                color="white"
                width="full"
                onClick={onInstall}
                my={4}
                className="btn btn-primary" // GTM: click on mobile banner store button
                fontSize={"lg"}
              >
                {t("components.AppBanner.Full.Install")}
              </Button>
            </ButtonGroup>
          </Flex>
        </VStack>
      </Flex>
    </RemoveScroll>
  )
}

export default AppBannerFull
