import { Box, IconButton } from "@chakra-ui/react"
import React from "react"
import { ReactComponent as CarouselArrow } from "~/images/CarouselArrow.svg"

interface ArrowNavigationProps {
  paginate: (direction: number) => void
}

const ArrowNavigation: React.FC<ArrowNavigationProps> = ({ paginate }) => {
  return (
    <Box display={{ base: "none", md: "block" }}>
      <IconButton
        id="carousel-prev"
        variant="ghost"
        onClick={() => paginate(-1)}
        aria-label="prev image"
        transform="translateY(-50%) rotate(180deg)"
        pos="absolute"
        top="50%"
        icon={<CarouselArrow />}
        zIndex={2}
        left={1}
        w={10}
        h={10}
        _hover={{ bg: "transparent" }}
        color={"black"}
      />

      <IconButton
        id="carousel-next"
        variant="ghost"
        className="arrow"
        onClick={() => paginate(1)}
        aria-label="next image"
        pos="absolute"
        top="50%"
        transform="translateY(-50%)"
        icon={<CarouselArrow />}
        zIndex={2}
        right={1}
        w={10}
        h={10}
        _hover={{ bg: "transparent" }}
        color={"black"}
      />
    </Box>
  )
}

export default ArrowNavigation
