import { Link, Wrap, WrapItem, WrapProps } from "@chakra-ui/react"
import { AvailableLanguages } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as AppStoreBadgeSvg } from "~/images/AppStoreBadge.svg"
import { ReactComponent as AppStoreBadgeSvgEs } from "~/images/AppStoreBadgeEs.svg"
import { ReactComponent as AppStoreBadgeSvgFrCA } from "~/images/AppStoreBadgeFrCA.svg"
import { ReactComponent as AppStoreBadgeSvgJaJP } from "~/images/AppStoreBadgeJaJP.svg"
import { ReactComponent as GooglePlayBadgeSvg } from "~/images/GooglePlayBadge.svg"
import { ReactComponent as GooglePlayBadgeSvgEs } from "~/images/GooglePlayBadgeEs.svg"
import { ReactComponent as GooglePlayBadgeSvgFrCA } from "~/images/GooglePlayBadgeFrCA.svg"
import { ReactComponent as GooglePlayBadgeSvgJaJP } from "~/images/GooglePlayBadgeJaJP.svg"

import {
  isAndroid,
  isIOS,
  isMacOs,
  isMobileSafari,
  isSafari,
} from "react-device-detect"

interface Props extends WrapProps {
  appStoreUrl?: string
  googlePlayUrl?: string
  language?: AvailableLanguages
  isCheckDevice?: boolean
}

const AppStoreButtons: React.FC<Props> = ({
  appStoreUrl,
  googlePlayUrl,
  language,
  direction,
  isCheckDevice = false,
  ...props
}) => {
  const { t } = useTranslation()

  const showAppStoreButton = appStoreUrl && appStoreUrl.trim().length > 0
  const showGooglePlayButton = googlePlayUrl && googlePlayUrl.trim().length > 0

  if (!showAppStoreButton && !showGooglePlayButton) {
    return null
  }

  const badgeProps = {
    height: "52px",
  }

  let appStoreBadge: JSX.Element

  switch (language) {
    case "fr-CA":
      appStoreBadge = <AppStoreBadgeSvgFrCA {...badgeProps} />
      break
    case "ja-JP":
      appStoreBadge = <AppStoreBadgeSvgJaJP {...badgeProps} />
      break
    case "es-US":
      appStoreBadge = <AppStoreBadgeSvgEs {...badgeProps} />
      break
    default:
      appStoreBadge = <AppStoreBadgeSvg {...badgeProps} />
  }

  let googlePlayBadge: JSX.Element

  switch (language) {
    case "fr-CA":
      googlePlayBadge = <GooglePlayBadgeSvgFrCA {...badgeProps} />
      break
    case "ja-JP":
      googlePlayBadge = <GooglePlayBadgeSvgJaJP {...badgeProps} />
      break
    case "es-US":
      googlePlayBadge = <GooglePlayBadgeSvgEs {...badgeProps} />
      break
    default:
      googlePlayBadge = <GooglePlayBadgeSvg {...badgeProps} />
  }

  const { justify = "flex-start", ...appStoreButtonProps } = props

  const AppStoreButton = () => (
    <WrapItem id="p-footer-app-store">
      <Link
        href={appStoreUrl}
        title={t("components.common.AppStoreButtons.AppStore")}
        isExternal
      >
        {appStoreBadge}
      </Link>
    </WrapItem>
  )

  const GooglePlayButton = () => (
    <WrapItem id="p-footer-google-play">
      <Link
        href={googlePlayUrl}
        title={t("components.common.AppStoreButtons.GooglePlay")}
        isExternal
      >
        {googlePlayBadge}
      </Link>
    </WrapItem>
  )

  return (
    <Wrap
      direction={direction}
      align="flex-start"
      justify={justify}
      spacing={2}
      {...appStoreButtonProps}
    >
      {isCheckDevice &&
        (isIOS || isMacOs || isSafari || isMobileSafari) &&
        showAppStoreButton && <AppStoreButton />}

      {isCheckDevice && isAndroid && showGooglePlayButton && (
        <GooglePlayButton />
      )}

      {!isCheckDevice && (
        <>
          {showAppStoreButton && <AppStoreButton />}
          {showGooglePlayButton && <GooglePlayButton />}
        </>
      )}
    </Wrap>
  )
}

export default React.memo(AppStoreButtons)
