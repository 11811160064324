import { EntityId } from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { ApiException } from "~/interfaces/entities/ApiException"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import { createEntitySlice } from "../createEntitySlice"

const { slice: orderSummaries, selectors: orderSummariesSelector } =
  createEntitySlice<OrderSummaryEntity>("orderSummaries")

export const emptyException: ApiException = { code: "", message: "" }

const orderSummariesFactory = (
  data: Partial<OrderSummaryEntity>
): OrderSummaryEntity => {
  return {
    id: nanoid(),
    error: emptyException,
    hasError: false,
    processingError: emptyException,
    hasProcessingError: false,
    isProcessing: false,
    isUpdating: false,
    isPendingAddress: false,
    isComplete: false,
    data: {
      id: -1,
      lineItemCount: 0,
      freightDiscount: "0",
      freightDiscountFloat: 0,
      freightDiscountHuman: "0",
      freight: "0",
      freightFloat: 0,
      freightHuman: "0",
      subtotal: "0",
      subtotalFloat: 0,
      subtotalHuman: "0",
      taxTotal: "0",
      taxTotalFloat: 0,
      taxTotalHuman: "0",
      subtotalDiscount: "0",
      subtotalDiscountFloat: 0,
      subtotalDiscountHuman: "0",
      discount: "0",
      discountFloat: 0,
      discountHuman: "0",
      total: "0",
      totalFloat: 0,
      totalHuman: "0",
      taxInclusive: false,
      totalQuantity: 0,
      currency: "",
      couponCode: "",
      lineItems: [],
    },
    ...data,
  }
}

const orderSummariesActions = {
  delete: (id: EntityId) => {
    return orderSummaries.actions.removeOne(id)
  },
  setIsUpdating: (id: EntityId, isUpdating: boolean) => {
    let changes
    if (isUpdating) {
      changes = {
        isUpdating: true,
        hasError: false,
        error: emptyException,
      }
    } else {
      changes = {
        isUpdating: false,
      }
    }
    return orderSummaries.actions.updateOne({
      id,
      changes: changes,
    })
  },
  setIsProcessing: (id: EntityId, isProcessing: boolean) => {
    let changes
    if (isProcessing) {
      changes = {
        isProcessing: true,
        hasError: false,
        error: emptyException,
        hasProcessingError: false,
        processingError: emptyException,
      }
    } else {
      changes = {
        isProcessing: false,
      }
    }

    return orderSummaries.actions.updateOne({
      id,
      changes,
    })
  },
  setIsPendingAddress: (id: EntityId, isPendingAddress: boolean) => {
    return orderSummaries.actions.updateOne({
      id,
      changes: {
        isPendingAddress,
      },
    })
  },
  setError: (id: EntityId, error: ApiException) => {
    return orderSummaries.actions.updateOne({
      id,
      changes: {
        hasError: true,
        error: error,
      },
    })
  },
  setProcessingError: (id: EntityId, error: ApiException) => {
    return orderSummaries.actions.updateOne({
      id,
      changes: {
        hasProcessingError: true,
        processingError: error,
      },
    })
  },
  setSummary: (id: EntityId, data: any) => {
    return orderSummaries.actions.updateOne({
      id,
      changes: {
        data,
      },
    })
  },
}

export {
  orderSummaries,
  orderSummariesActions,
  orderSummariesSelector,
  orderSummariesFactory,
}
