import { ChakraProvider, HStack, Link, VStack } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import SocialMediaLink from "~/components/SocialMediaLink"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("socialMediaButtons", html, args => {
    const {
      justify = "center",
      align = "center",
      layout = "vertical",
    } = args.attrs.named

    const appConfig = state.appConfig!
    const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = appConfig
    const theme = makeTheme(appConfig)

    const horizontalSocialMediaButtons = (
      <ChakraProvider theme={theme}>
        <HStack spacing={10} align={align} justify={justify}>
          {facebookUrl && (
            <SocialMediaLink size="20px" icon={FaFacebook} link={facebookUrl} />
          )}

          {instagramUrl && (
            <SocialMediaLink
              size="20px"
              icon={FaInstagram}
              link={instagramUrl}
            />
          )}

          {twitterUrl && (
            <SocialMediaLink size="20px" icon={FaXTwitter} link={twitterUrl} />
          )}

          {youtubeUrl && (
            <SocialMediaLink size="20px" icon={FaYoutube} link={youtubeUrl} />
          )}
        </HStack>
      </ChakraProvider>
    )

    const verticalSocialMediaButtons = (
      <ChakraProvider theme={theme}>
        <VStack spacing={3} align="flex-start" mb={8}>
          {facebookUrl && (
            <HStack id={`navigation-facebook`}>
              <SocialMediaLink icon={FaFacebook} link={facebookUrl} />
              <Link href={facebookUrl} rel="noopener" isExternal>
                Facebook
              </Link>
            </HStack>
          )}

          {instagramUrl && (
            <HStack id={`navigation-instagram`}>
              <SocialMediaLink icon={FaInstagram} link={instagramUrl} />
              <Link href={instagramUrl} rel="noopener" isExternal>
                Instagram
              </Link>
            </HStack>
          )}
          {twitterUrl && (
            <HStack id={`navigation-x`}>
              <SocialMediaLink icon={FaXTwitter} link={twitterUrl} />
              <Link href={twitterUrl} rel="noopener" isExternal>
                𝕏
              </Link>
            </HStack>
          )}

          {youtubeUrl && (
            <HStack id={`navigation-youtube`}>
              <SocialMediaLink icon={FaYoutube} link={youtubeUrl} />
              <Link href={youtubeUrl} rel="noopener" isExternal>
                Youtube
              </Link>
            </HStack>
          )}
        </VStack>
      </ChakraProvider>
    )

    return renderShortCode({
      type: "socialMediaButtons",
      args,
      component:
        layout === "vertical"
          ? verticalSocialMediaButtons
          : horizontalSocialMediaButtons,
    })
  })
}
