import { Badge } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"
import { getFallbackTextColor } from "../Utils"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("badge", html, args => {
    const {
      colors: { primary },
    } = makeTheme(state.appConfig!)

    const text = args.attrs.named.text
    if (!text) {
      return `[badge error="text cannot be empty"]`
    }
    const bg = args.attrs.named.bg ?? primary[500]
    const color = args.attrs.named.color ?? getFallbackTextColor(primary[500])
    const fontSize = args.attrs.named.fontsize ?? "var(--chakra-fontSizes-xs)" //named attributes must be lowercases "fontsize"
    const border = args.attrs.named.border ?? "none"

    return renderShortCode({
      type: "badge",
      args,
      component: (
        <Badge
          colorScheme="primary"
          padding="0.35em 0.5em"
          fontWeight="600"
          borderRadius="5px"
          whiteSpace="nowrap"
          lineHeight="1"
          color={color}
          bg={bg}
          fontSize={fontSize}
          border={border}
        >
          {text}
        </Badge>
      ),
    })
  })
}
