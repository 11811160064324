import {
  EntityId,
  PageEntity,
  PrintServiceEntityHydrated,
  PrintServiceProductEntityHydrated,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { intersection, uniq } from "lodash"
import { call, getContext, put, select, takeLatest } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import { PrinticularApi } from "~/services/PrinticularApi"
import { calculateLatLngDistanceInKm } from "~/services/Utils"
import { actions } from "../process"
import { cartsSelector } from "../state/carts"
import { lineItemsSelectors } from "../state/lineItems"
import { pageSessions, pageSessionsSelector } from "../state/pageSessions"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { printServicesSelector } from "../state/printServices"
import { stores } from "../state/stores"
import { RootState } from "../store"
import { getApi } from "./api"
import { getCurrentPage } from "./page"
import { getPageProductsForFulfillment } from "./printServiceProducts"
import { getPrintServiceByRemoteId } from "./printServices"

export default function* watchProcessStoreSearch() {
  yield takeLatest(actions.searchStores.type, processStoreSearch)
}

function* processStoreSearch(): SagaIterator {
  yield put(stores.actions.setIsLoading(true))
  yield put(stores.actions.setHasError(false))
  yield put(stores.actions.setError(""))

  try {
    const getCurrentPageId = yield getContext("getCurrentPageId")
    const currentPageId = getCurrentPageId()

    const printicularApi: PrinticularApi = yield call(getApi)

    const pageSession: PageSessionEntity = yield select((state: RootState) =>
      pageSessionsSelector.selectById(state, currentPageId)
    )

    const page: PageEntity = yield call(getCurrentPage)

    const cart: CartEntity = yield select((state: RootState) =>
      cartsSelector.selectById(state, pageSession.cartId)
    )

    const selectedPrintServiceIds = page.printServices[cart.fulfillment].map(
      data => data.id
    )

    const printServices: PrintServiceEntityHydrated[] = yield select(
      (state: RootState) =>
        printServicesSelector.selectByIds(state, selectedPrintServiceIds)
    )

    let productCodes: EntityId[] = []
    if (pageSession.pageFlow === "product-first") {
      // product first, we get all product ids for the current cart
      // product ids will be used to filter store based on the product list availability
      const lineItems: LineItemEntity[] = yield select((state: RootState) =>
        lineItemsSelectors.selectByIds(state, cart.lineItemIds)
      )

      const readyLineItems = lineItems.filter(lineItem => lineItem.isReady)
      const allPrintServiceProductIds = readyLineItems.map(
        lineItem => lineItem.productId
      )

      const printServiceProducts: PrintServiceProductEntityHydrated[] =
        yield select((state: RootState) =>
          printServiceProductsSelector.selectByIds(
            state,
            allPrintServiceProductIds
          )
        )
      productCodes = uniq(
        printServiceProducts.map(
          printServiceProduct => printServiceProduct?.productCode
        )
      )
    }

    const latLng = { lat: cart.storeLat, lng: cart.storeLng }
    const retailerIds = cart.retailerIds

    // and add this list to the available product ids on the page session
    let allStores: StoreEntity[] = yield call(() =>
      printicularApi.getAvailableStoresForMultiplePrintServices(
        latLng,
        printServices.map(printService => printService.remoteId),
        productCodes,
        retailerIds
      )
    )

    if (pageSession.pageFlow === "store-first") {
      // store first, we now need to remove uncompatible stores
      // from the list by comparing remote product IDs
      const printServiceProducts: PrintServiceProductEntityHydrated[] =
        yield call(getPageProductsForFulfillment, {
          fulfillment: "pickup",
          pageId: currentPageId,
        })
      const printServiceProductCodes = printServiceProducts.map(
        product => product.productCode
      )

      allStores = allStores.filter(aStore => {
        const compatibleProductIds = intersection(
          aStore.products,
          printServiceProductCodes
        )

        return compatibleProductIds.length > 0
      })
    }

    // apply print service local id to store entities
    for (let i = 0; i < allStores.length; i++) {
      const store = allStores[i]
      const localPrintService = yield call(getPrintServiceByRemoteId, {
        remoteId: store.printServiceId,
      })
      allStores[i].printServiceId = localPrintService.id
    }

    const { lat: searchLat, lng: searchLng } = latLng
    allStores = allStores.map((store: StoreEntity) => ({
      ...store,
      distanceToSearchInKms: calculateLatLngDistanceInKm(
        searchLat,
        searchLng,
        parseFloat(store.latitude),
        parseFloat(store.longitude)
      ),
    }))

    const sortedStoresByDistance = [...allStores].sort(
      (a: StoreEntity, b: StoreEntity) =>
        a.distanceToSearchInKms - b.distanceToSearchInKms
    )

    // upsert store list
    yield put(stores.actions.upsertMany(sortedStoresByDistance))
    // update available store to page session
    yield put(
      pageSessions.actions.updateOne({
        id: pageSession.id,
        changes: {
          storeIds: sortedStoresByDistance.map((s: StoreEntity) => s.id),
          hasLoadedStores: sortedStoresByDistance.length > 0,
        },
      })
    )
    // set default selected store
    if (sortedStoresByDistance.length > 0) {
      const previouslySelectedId = cart.storeId

      const foundStore = sortedStoresByDistance.find(
        (store: StoreEntity) => store.id === previouslySelectedId
      )
      const storeToSelect = foundStore ?? sortedStoresByDistance[0]

      yield put(
        actions.updateStore({
          storeId: storeToSelect.id,
        })
      )
    }
  } catch (error: any) {
    console.error(error)
    yield put(stores.actions.setError(error.message))
    yield put(stores.actions.setHasError(true))
  } finally {
    yield put(stores.actions.setIsLoading(false))
    yield put(
      actions.updateOrderSummary({
        reason: "store search",
        reasonType: "storeChanged",
      })
    )
  }
}
