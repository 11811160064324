import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useTheme,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"
import { t as translateProp } from "i18next"
import { useBoolean } from "react-use"
import { useTranslation } from "react-i18next"
import { scoreMax } from "@jackfruit/common"

interface Props {
  id?: string
  name?: string
  autoComplete?: string
  placeholder?: string
  validation?: any
  isSecurePasswordEnabled?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  isFormLabelVisible?: boolean
  score?: number
}

const PasswordInput: React.FC<Props> = ({
  id,
  name = "password",
  placeholder = translateProp(
    "components.account.PasswordForm.PasswordInput.Default"
  ),
  validation = {
    required: translateProp(
      "components.account.PasswordForm.PasswordInput.DefaultValidation"
    ),
  },
  autoComplete = "current-password",
  isSecurePasswordEnabled = false,
  isDisabled = false,
  isRequired = true,
  isFormLabelVisible = false,
  score,
}) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState("")
  const [isPasswordVisible, setIsPasswordVisible] = useBoolean(false)

  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
  } = useFormContext()

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = e.target.value
      setPassword(nextValue)
      setValue(name, nextValue, {
        shouldDirty: true,
        shouldValidate: isSubmitted,
      })
    },
    [isSubmitted, name, setValue]
  )

  return (
    <FormControl
      id="sign-in-password"
      isInvalid={!!errors[name]}
      isRequired={isRequired}
    >
      {isFormLabelVisible && <FormLabel>{placeholder}</FormLabel>}
      <Box mb={score && score ? 3 : 0}>
        <InputGroup size="md">
          <Input
            data-testid="sign-in-password-input"
            id={id}
            pr="4.5rem"
            type={isPasswordVisible ? "text" : "password"}
            placeholder={placeholder}
            value={password}
            variant="filled"
            autoComplete={autoComplete}
            {...register(name, validation)}
            isDisabled={isDisabled}
            onChange={onChange}
          />
          {password && (
            <InputRightElement width="4.5rem">
              <Button
                id="password-toggle"
                h="1.75rem"
                size="sm"
                isDisabled={isDisabled}
                onClick={setIsPasswordVisible}
              >
                {isPasswordVisible
                  ? t("components.account.PasswordForm.PasswordInput.Hide")
                  : t("components.account.PasswordForm.PasswordInput.Show")}
              </Button>
            </InputRightElement>
          )}
        </InputGroup>

        {isSecurePasswordEnabled && password && <ScoreProgress score={score} />}

        {!!errors[name] && (
          <FormErrorMessage paddingRight={10}>
            {errors[name].message}
          </FormErrorMessage>
        )}
      </Box>
    </FormControl>
  )
}

export default React.memo(PasswordInput)

const ScoreProgress = ({ score: rawScore }: { score?: number }) => {
  const score = rawScore ? rawScore - 1 : -1
  const { t } = useTranslation()

  const {
    colors: { red, orange, blue, green },
  } = useTheme()

  const weaknessScale = [
    {
      color: red[500],
      information: t(
        "components.account.PasswordForm.PasswordInput.ScoreProgress.Weak"
      ),
    },
    {
      color: orange[500],
      information: t(
        "components.account.PasswordForm.PasswordInput.ScoreProgress.Okay"
      ),
    },
    {
      color: blue[500],
      information: t(
        "components.account.PasswordForm.PasswordInput.ScoreProgress.Good"
      ),
    },
    {
      color: green[500],
      information: t(
        "components.account.PasswordForm.PasswordInput.ScoreProgress.Strong"
      ),
    },
  ]

  const currentScale = weaknessScale[score]
  return (
    <Box mt={1}>
      <HStack>
        {[...Array(scoreMax).keys()].map(index => {
          const color =
            index > score || score === -1 ? "gray.200" : currentScale.color
          return <ScoreBar key={index} color={color} />
        })}
      </HStack>

      {score !== -1 && (
        <Text
          color="gray.400"
          mt={0}
          mb={0}
          fontSize="sm"
          position="absolute"
          right={0}
        >
          {currentScale.information}
        </Text>
      )}
    </Box>
  )
}

const ScoreBar = ({ color }: { color: string }) => {
  return (
    <Box bg={color} height={1} width={`${100 / scoreMax}%`} borderRadius={4} />
  )
}
