import { Channel, channel, SagaIterator } from "redux-saga"
import { call, delay, fork, put, retry, select, take } from "redux-saga/effects"
import { ImageEntity } from "~/interfaces/entities/Image"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { logger } from "~/services/Logger"
import { actions, UploadFilePayload } from "../process"
import { images } from "../state/images"
import { uploads, uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import {
  compressImage,
  getImage,
  processUploadToS3,
  resizeImage,
} from "./images"
import { getUpload } from "./uploads"

const PROCESS_WORKERS_COUNT = 4

export function* watchQueueImageForProcessing(): SagaIterator {
  const chan = yield call(channel)

  for (let i = 0; i < PROCESS_WORKERS_COUNT; i++) {
    yield fork(handleProcessImage, chan)
  }

  while (true) {
    const { payload } = yield take(actions.queueImageForProcessing.type)
    yield put(chan, payload)
  }
}

function* handleProcessImage(
  chan: Channel<UploadFilePayload>
): SagaIterator<any> {
  while (true) {
    const payload = yield take(chan)
    const { id, file } = payload
    const upload: UploadEntity = yield call(getUpload, id)
    const image: ImageEntity = yield call(getImage, upload.imageId)

    yield put(
      uploads.actions.updateOne({
        id,
        changes: {
          status: "resizing",
          isReady: false,
        },
      })
    )
    // create a small version of the image for local manipulation
    // image sent to the server will be the full sized one
    const resizedImage = yield call(resizeImage, file)
    yield put(
      images.actions.updateOne({
        id: image.id,
        changes: {
          localUrl: window.URL.createObjectURL(resizedImage),
        },
      })
    )

    yield put(
      uploads.actions.updateOne({
        id,
        changes: {
          status: "resized",
          isReady: false,
        },
      })
    )

    yield put(
      actions.queueImageForUploading({
        id: id,
        file,
      })
    )
  }
}

// TODO fine tune to match S3 configuration
const UPLOAD_WORKERS_COUNT = 2
const UPLOAD_DELAY = 250 // ms
const UPLOAD_RETRY_COUNT = 3
const UPLOAD_RETRY_DELAY = 2 * 1000 // ms

export function* watchQueueImageForUploading(): SagaIterator {
  const chan = yield call(channel)

  for (let i = 0; i < UPLOAD_WORKERS_COUNT; i++) {
    yield fork(handleProcessUpload, chan)
  }

  while (true) {
    const { payload } = yield take(actions.queueImageForUploading.type)
    yield put(chan, payload)
  }
}

function* handleProcessUpload(
  chan: Channel<UploadFilePayload>
): SagaIterator<any> {
  while (true) {
    const payload = yield take(chan)
    yield delay(UPLOAD_DELAY)
    const { id, file } = payload

    // compress image
    yield put(
      uploads.actions.updateOne({
        id,
        changes: {
          status: "compressing",
          isReady: false,
        },
      })
    )

    const compressedImage = yield call(compressImage, file)

    yield put(
      uploads.actions.updateOne({
        id,
        changes: {
          status: "compressed",
          isReady: false,
        },
      })
    )

    try {
      // upload to s3
      yield put(
        uploads.actions.updateOne({
          id,
          changes: {
            status: "uploading",
            isReady: false,
          },
        })
      )

      yield retry(UPLOAD_RETRY_COUNT, UPLOAD_RETRY_DELAY, processUploadToS3, {
        id,
        file: compressedImage,
      })

      const upload: UploadEntity = yield select((state: RootState) =>
        uploadsSelectors.selectById(state, id)
      )
      //update image with s3 upload info
      yield put(
        images.actions.updateOne({
          id: upload.imageId,
          changes: {
            isReady: false,
            externalUrl: upload.location,
            key: upload.key,
            filename: upload.name,
          },
        })
      )

      yield put(
        uploads.actions.updateOne({
          id,
          changes: {
            status: "ready",
            isReady: true,
          },
        })
      )
    } catch (error) {
      logger.error(error)
      yield put(
        uploads.actions.updateOne({
          id,
          changes: {
            error: "unable to recover from last error",
            status: "fatal_error",
            isReady: false,
          },
        })
      )
    }
  }
}
