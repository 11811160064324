import {
  PrintServiceProductEntityHydrated,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { times } from "lodash"
import {
  PageType,
  ProductPageEntityHydrated,
} from "~/interfaces/entities/ProductPage"

export const getPagesFromProduct = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  switch (product.categoryName) {
    // case "apparel":
    // case "badge":
    // case "blanket":
    case "book":
    case "calendar":
    case "lenticular-card":
    case "ornament":
    case "tile":
      return getPagesFromMultiPageProduct(product)
    case "photo-book":
      return getPagesFromPhotoBook(product)
    // case "canvas":
    case "card":
      return getPagesFromCard(product)
    // case "collage":
    // case "drinkware":
    // case "edible print":
    // case "flooring":
    // case "magnet":
    // case "metal":
    // case "mug":
    // case "other":
    // case "panel":
    // case "poster":
    // case "puzzle":
    // case "wallet":
    case "apparel":
      //case "wrapping-paper":
      return getPagesFromOverlay(product)
    case "print":
    case "instax-style-print":
    case "floating frame":
      return getPagesFromDefault(product)
    default:
      return getPagesFromDefault(product)
  }
}

const getPagesFromDefault = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  const wrap = product.metaData?.wrap || 0
  const width = product.pixelWidth + wrap * 2
  const height = product.pixelHeight + wrap * 2

  const firstPage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.Page,
    width,
    height,
    imageRegions: [
      {
        id: nanoid(),
        window: {
          x: 0,
          y: 0,
          width,
          height,
        },
      },
    ],
    textRegions: [],
  }

  return [firstPage]
}

const getPagesFromPrint = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  return getPagesFromDefault(product)
}

const getPagesFromCard = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  const { minImages } = product

  const pages: ProductPageEntityHydrated[] = times(minImages).map(
    _index => getPagesFromDefault(product)[0]
  )

  return pages
}

const getPagesFromMultiPageProduct = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  const { minImages } = product

  // TODO add INP optimisation here
  const pages: ProductPageEntityHydrated[] = times(minImages).map(
    _index => getPagesFromDefault(product)[0]
  )

  return pages
}

const getPagesFromFloatingFrame = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  const portraitProductImage = product.printServiceProductImages!.find(
    productImage =>
      productImage.metaData?.frame?.width! <=
      productImage.metaData?.frame?.height!
  )

  const firstProductImage =
    portraitProductImage || product.printServiceProductImages![0]
  const innerFrameRegion = firstProductImage.metaData.frame?.window

  const firstPage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.Page,
    width: product.pixelWidth,
    height: product.pixelHeight,
    imageRegions: [
      {
        id: nanoid(),
        window: innerFrameRegion!,
      },
    ],
    textRegions: [],
  }
  return [firstPage]
}

const getPagesFromOverlay = (
  product: PrintServiceProductEntityHydrated
): ProductPageEntityHydrated[] => {
  const portraitProductImage = product.printServiceProductImages!.find(
    productImage =>
      productImage.metaData?.frame?.width! <=
      productImage.metaData?.frame?.height!
  )

  const firstProductImage =
    portraitProductImage || product.printServiceProductImages![0]
  const innerFrameRegion = firstProductImage.metaData?.frame?.window

  const firstPage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.Page,
    width: product.pixelWidth,
    height: product.pixelHeight,
    imageRegions: [
      {
        id: nanoid(),
        window: innerFrameRegion!,
      },
    ],
    textRegions: [],
  }
  return [firstPage]
}

const getPagesFromPhotoBook = (product: PrintServiceProductEntityHydrated) => {
  // TODO add INP optimisation here (not available on current branch)
  const {
    printServiceProductImages = [],
    minImages,
    pixelHeight,
    pixelWidth,
  } = product

  // Create Cover Page
  const frontCoverImage = printServiceProductImages.find(image => {
    return (
      image.metaData?.photoBook?.frameType === "windowCover" ||
      image.metaData?.photoBook?.frameType === "cover"
    )
  }) as PrintServiceProductImageEntity

  const frontCoverPage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.FrontCover,
    width: frontCoverImage?.metaData?.frame?.width || pixelWidth,
    height: frontCoverImage?.metaData?.frame?.height || pixelHeight,
    seeThrough:
      frontCoverImage.metaData?.photoBook?.frameType === "windowCover",
    imageRegions: [],
    textRegions: [],
  }

  // Create Inside Cover Page
  const frontCoverInsideImage = printServiceProductImages.find(
    image => image.metaData?.photoBook?.frameType === "insideCover"
  ) as PrintServiceProductImageEntity

  const frontCoverInsidePage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.FrontCoverInside,
    width: frontCoverInsideImage?.metaData?.frame?.width || pixelWidth,
    height: frontCoverInsideImage?.metaData?.frame?.height || pixelHeight,
    imageRegions: [],
    textRegions: [],
  }

  const insidePages = times(minImages).map(_pageIndex => {
    return {
      id: nanoid(),
      pageType: PageType.Page,
      width: pixelWidth,
      height: pixelHeight,
      imageRegions: [
        {
          id: nanoid(),
          window: {
            x: 0,
            y: 0,
            width: pixelWidth,
            height: pixelHeight,
          },
        },
      ],
      textRegions: [],
    }
  })

  // Create Back Cover Inside
  const backCoverInsideImage = printServiceProductImages.find(
    image => image.metaData?.photoBook?.frameType === "lastPage"
  ) as PrintServiceProductImageEntity

  const backCoverInsidePage: ProductPageEntityHydrated = {
    id: nanoid(),
    pageType: PageType.BackCoverInside,
    width: backCoverInsideImage?.metaData?.frame?.width || pixelWidth,
    height: backCoverInsideImage?.metaData?.frame?.height || pixelHeight,
    imageRegions: [],
    textRegions: [],
  }

  const allBookPages = [
    frontCoverPage,
    frontCoverInsidePage,
    ...insidePages,
    backCoverInsidePage,
  ]

  return allBookPages
}
