import {
  EntityId,
  PrinticularProductTemplateImageRegion,
  PrinticularProductTemplatePage,
  PrinticularProductTemplateTextRegion,
  PrinticularProductTemplateVariant,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { nanoid } from "@reduxjs/toolkit"
import { call, put, select } from "redux-saga/effects"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { getDefaultImageTransformation } from "~/services/Utils"
import { imageRegions, imageRegionsSelectors } from "../state/imageRegions"
import { images, imagesSelectors } from "../state/images"
import {
  imageTransformations,
  imageTransformationsSelectors,
} from "../state/imageTransformations"
import { productPages, productPagesSelectors } from "../state/productPages"
import { textRegions } from "../state/textRegions"
import { uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"

// ====================================
// create missing product pages
// remove extra product pages
// ====================================
export function* prepareProductPagesForVariant({
  productPageIds,
  variant,
}: {
  productPageIds: EntityId[]
  variant: PrinticularProductTemplateVariant
}) {
  const variantPages = variant.pages
  const updatedProductPageIds: EntityId[] = []

  for (let i = 0; i < variantPages.length; i++) {
    let correspondingProductPageId = productPageIds[i]

    if (correspondingProductPageId === undefined) {
      correspondingProductPageId = yield call(createProductPage, {
        page: variantPages[i],
      })
    } else {
      yield call(updateProductPages, {
        productPageIds: [correspondingProductPageId],
        variant,
      })
    }

    updatedProductPageIds.push(correspondingProductPageId)
  }

  return updatedProductPageIds
}

// ====================================
// create page and image regions
// ====================================
export function* createProductPage({
  page,
}: {
  page: PrinticularProductTemplatePage
}): SagaIterator<any> {
  const pageImageRegions = page.imageRegions
  const imageRegionIds: EntityId[] = []
  for (let i = 0; i < pageImageRegions.length; i++) {
    const imageRegionId = yield call(createImageRegion, {
      pageImageRegion: pageImageRegions[i],
    })

    imageRegionIds.push(imageRegionId)
  }

  const pageTextRegions = page.textRegions ?? []

  const textRegionIds: EntityId[] = []
  for (let i = 0; i < pageTextRegions.length; i++) {
    const textRegionId = yield call(createTextRegion, {
      pageTextRegion: pageTextRegions[i],
    })

    textRegionIds.push(textRegionId)
  }

  const id = nanoid()
  yield put(
    productPages.actions.addOne({
      id,
      name: page.name,
      svg: page.svg,
      width: page.width,
      height: page.height,
      imageRegionIds,
      textRegionIds,
    })
  )

  return id
}

// ====================================
// create image regions
// ====================================
function* createTextRegion({
  pageTextRegion,
}: {
  pageTextRegion: PrinticularProductTemplateTextRegion
}) {
  const window = {
    x: pageTextRegion.x,
    y: pageTextRegion.y,
    width: pageTextRegion.width,
    height: pageTextRegion.height,
  }

  const textRegion = {
    id: nanoid(),
    window: window,
    align: pageTextRegion.align,
    color: pageTextRegion.color,
    font: pageTextRegion.font,
    key: pageTextRegion.key,
    size: Number(pageTextRegion.size),
    defaultSize: Number(pageTextRegion.size),
    placeholder: pageTextRegion.placeholder,
    text: "",
  }

  yield put(textRegions.actions.addOne(textRegion))

  return textRegion.id
}

// ====================================
// create image regions
// ====================================
function* createImageRegion({
  pageImageRegion,
}: {
  pageImageRegion: PrinticularProductTemplateImageRegion
}) {
  const window = {
    x: pageImageRegion.x,
    y: pageImageRegion.y,
    width: pageImageRegion.width,
    height: pageImageRegion.height,
  }

  const imageRegion = {
    id: nanoid(),
    window: window,
  }

  yield put(imageRegions.actions.addOne(imageRegion))

  return imageRegion.id
}

// ====================================
// update pages based on template
// variant specifications
// ====================================
export function* updateProductPages({
  productPageIds,
  variant,
}: {
  productPageIds: EntityId[]
  variant: PrinticularProductTemplateVariant
}): SagaIterator<any> {
  const currentProductPages = yield select((state: RootState) =>
    productPagesSelectors.selectByIds(state, productPageIds)
  )

  for (let i = 0; i < productPageIds.length; i++) {
    const currentProductPage = currentProductPages[i]
    const currentVariantPage = variant.pages[i]

    yield call(updateImageRegions, {
      productPageId: currentProductPage.id,
      imageRegionIds: currentProductPage.imageRegionIds,
      variantPage: currentVariantPage,
    })

    yield put(
      productPages.actions.updateOne({
        id: currentProductPage.id,
        changes: {
          svg: currentVariantPage.svg,
          width: currentVariantPage.width,
          height: currentVariantPage.height,
        },
      })
    )
  }
}

// ====================================
// update image regions based on
// variant page image region specs
// ====================================
export function* updateImageRegions({
  productPageId,
  imageRegionIds,
  variantPage,
}: {
  productPageId: EntityId
  imageRegionIds: EntityId[]
  variantPage: PrinticularProductTemplatePage
}): SagaIterator<any> {
  const currentImageRegions = yield select((state: RootState) =>
    imageRegionsSelectors.selectByIds(state, imageRegionIds)
  )

  // update existing image regions
  for (let i = 0; i < currentImageRegions.length; i++) {
    const currentImageRegion = currentImageRegions[i]
    const currentVariantImageRegion = variantPage.imageRegions[i]

    yield call(updateImageRegion, {
      imageRegion: currentImageRegion,
      variantImageRegion: currentVariantImageRegion,
    })
  }

  if (variantPage.imageRegions.length > currentImageRegions.length) {
    // create missing image regions
    const allImageRegionIds = [...imageRegionIds]
    const startingIndex = currentImageRegions.length

    for (let i = startingIndex; i < variantPage.imageRegions.length; i++) {
      const newImageRegionId = yield call(createImageRegion, {
        pageImageRegion: variantPage.imageRegions[i],
      })
      allImageRegionIds.push(newImageRegionId)
    }

    yield put(
      productPages.actions.updateOne({
        id: productPageId,
        changes: {
          imageRegionIds: allImageRegionIds,
        },
      })
    )
  } else if (variantPage.imageRegions.length < currentImageRegions.length) {
    // remove extra images otherwise validation will prevent the
    // line item from being validated
    const allImageRegionIds = [...imageRegionIds].slice(
      0,
      variantPage.imageRegions.length
    )

    yield put(
      productPages.actions.updateOne({
        id: productPageId,
        changes: {
          imageRegionIds: allImageRegionIds,
        },
      })
    )
  }
}

// ====================================
// update image based on variant
// image specs
// ====================================
export function* updateImageRegion({
  imageRegion,
  variantImageRegion,
}: {
  imageRegion: ImageRegionEntity
  variantImageRegion: PrinticularProductTemplateImageRegion
}) {
  const newImageRegion = variantImageRegion || {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }

  // apply transformations
  const newImageRegionWindow = newImageRegion

  yield put(
    imageRegions.actions.updateOne({
      id: imageRegion.id,
      changes: {
        window: newImageRegionWindow,
      },
    })
  )

  const targetWindow = {
    width: newImageRegion.width,
    height: newImageRegion.height,
  }

  if (imageRegion.uploadId) {
    const upload: UploadEntity = yield select((state: RootState) =>
      uploadsSelectors.selectById(state, imageRegion.uploadId!)
    )
    const image: ImageEntity = yield select((state: RootState) =>
      imagesSelectors.selectById(state, upload.imageId)
    )

    const imageTransformation: ImageTransformationEntity = yield select(
      (state: RootState) =>
        imageTransformationsSelectors.selectById(state, image.imageTransformId)
    )

    const imageDimensions = {
      width: image.width,
      height: image.height,
    }

    const defaultImageTransformation = getDefaultImageTransformation(
      targetWindow,
      imageDimensions
    )

    yield put(
      images.actions.updateOne({
        id: image.id,
        changes: {
          ...imageDimensions,
        },
      })
    )

    yield put(
      imageTransformations.actions.updateOne({
        id: imageTransformation.id,
        changes: {
          rotation: 0,
          translation: {
            x: defaultImageTransformation.x,
            y: defaultImageTransformation.y,
          },
          zoom: defaultImageTransformation.zoom,
          minZoom: defaultImageTransformation.zoom,
          dirty: false,
        },
      })
    )
  }
}
