import {
  BlockType,
  EntityId,
  FulfillmentTypes,
  PageEntity,
  PrinticularOrder,
  PrintServiceEntity,
  PrintServiceEntityHydrated,
  PrintServiceProductEntity,
  PrintServiceProductEntityHydrated,
  PrintServiceProductImageEntity,
  PrintServiceProductPriceEntity,
  TemplateTextFont,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { nanoid } from "@reduxjs/toolkit"
import { ValidationOptions } from "joi"
import { chunk, flatten, orderBy, uniq } from "lodash"
import React from "react"
import { renderToString } from "react-dom/server"
import {
  all,
  call,
  delay,
  getContext,
  put,
  retry,
  select,
} from "redux-saga/effects"
import ProductPageFoldedCardRenderer from "~/components/editors/common/ProductPageFoldedCardRenderer"
import ProductPageRenderer, {
  RendererPage,
} from "~/components/editors/common/ProductPageRenderer"
import { actionableToBlocks } from "~/hooks/useNextActionableBlock"
import { Address } from "~/interfaces/entities/Address"
import { CartEntity } from "~/interfaces/entities/Cart"
import { GiftCertificateEntity } from "~/interfaces/entities/GiftCertificate"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { Payment } from "~/interfaces/entities/Payment"
import {
  PageTypeToSkipForUpload,
  ProductPageEntity,
} from "~/interfaces/entities/ProductPage"
import { StoreEntity } from "~/interfaces/entities/Store"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { User } from "~/interfaces/entities/User"
import { isInternalServerError } from "~/services/ApiExceptionHelper"
import { PrinticularApi } from "~/services/PrinticularApi"
import { PrinticularSerializer } from "~/services/PrinticularSerializer"
import { getLocalStorageItem, setLocalStorageItem } from "~/services/Utils"
import {
  deliveryOrderAddressValidationSchema,
  deliveryOrderBaseValidationSchema,
  deliveryOrderPaymentValidationSchema,
  pickupOrderValidationSchema,
} from "~/validators/order"
import { CartState } from "../state/cart"
import { cartsSelector } from "../state/carts"
import { giftCertificatesSelectors } from "../state/giftCertificates"
import { imageRegionsSelectors } from "../state/imageRegions"
import { images, imagesSelectors } from "../state/images"
import { imageTransformationsSelectors } from "../state/imageTransformations"
import { lineItems as lineItemSlice } from "../state/lineItems"
import { orderSummariesSelector } from "../state/orderSummaries"
import { pagesSelector } from "../state/pages"
import { pageSessionsSelector } from "../state/pageSessions"
import { printServiceProductPricesSelector } from "../state/printServiceProductPrices"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { printServicesSelector } from "../state/printServices"
import { productPagesSelectors } from "../state/productPages"
import { storesSelectors } from "../state/stores"
import { templateTextFontsSelector } from "../state/template-text-fonts"
import { territoriesSelectors } from "../state/territories"
import { textRegionsSelectors } from "../state/textRegions"
import { uploads, uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import { getApi } from "./api"
import { getBlocks } from "./blocks"
import { getCartLineItems } from "./cart"
import { createImage, processUploadToS3 } from "./images"
import { getLineItem, getLineItemFrame } from "./lineItems"
import { getPage } from "./page"

type OrderPlacementOption = "dryRun" | "placeOrder" | "createOrder"

interface OrderData {
  fulfillment: FulfillmentTypes
  store: StoreEntity
  payment: Payment
  user: User
  address: Address
  lineItems: LineItemEntity[]
  images: ImageEntity[]
  uploads: UploadEntity[]
  printService: PrintServiceEntityHydrated
  printServiceProducts: PrintServiceProductEntityHydrated[]
}

interface OrderValidationResult {
  isValid: boolean
  isAddressComplete?: boolean
  errorMessage?: string
}

export function validatePickupOrder(
  order: OrderData,
  validationOptions: ValidationOptions
): OrderValidationResult {
  const { error } = pickupOrderValidationSchema.validate(
    order,
    validationOptions
  )

  return {
    isValid: error === undefined,
    isAddressComplete: true,
    errorMessage: error?.message,
  }
}

export function validateDeliveryOrder(
  order: OrderData,
  validationOptions: ValidationOptions
): OrderValidationResult {
  // Validate base order details
  const { error: baseError } = deliveryOrderBaseValidationSchema.validate(
    order,
    validationOptions
  )
  if (baseError) {
    return {
      isValid: false,
      errorMessage: baseError.message,
    }
  }

  // Validate order address details
  const { error: addressError } = deliveryOrderAddressValidationSchema.validate(
    order,
    validationOptions
  )
  if (addressError) {
    return {
      isValid: false,
      isAddressComplete: false,
      errorMessage: addressError.message,
    }
  }

  // Validate order payment details
  const { error: paymentError } = deliveryOrderPaymentValidationSchema.validate(
    order,
    validationOptions
  )
  if (paymentError) {
    return {
      isValid: false,
      errorMessage: paymentError.message,
    }
  }

  return {
    isValid: true,
    isAddressComplete: true,
  }
}

export function validateOrder(order: OrderData) {
  const validationOptions = {
    allowUnknown: true,
    abortEarly: false,
  }

  switch (order.fulfillment) {
    case "pickup":
      return validatePickupOrder(order, validationOptions)
    case "delivery":
      return validateDeliveryOrder(order, validationOptions)
    default:
      return {
        isValid: false,
        errorMessage: "Invalid order fulfillment type passed.",
      }
  }
}

export function validateDeliveryOrderForSummary(
  order: OrderData,
  validationOptions: ValidationOptions
): OrderValidationResult {
  // Validate base order details
  const { error: baseError } = deliveryOrderBaseValidationSchema.validate(
    order,
    validationOptions
  )
  if (baseError) {
    return {
      isValid: false,
      errorMessage: baseError.message,
    }
  }

  // Validate order address details
  const { error: addressError } = deliveryOrderAddressValidationSchema.validate(
    order,
    validationOptions
  )
  let isAddressComplete = true
  if (addressError) {
    isAddressComplete = false
  }

  // Validate order payment details
  const { error: paymentError } = deliveryOrderPaymentValidationSchema.validate(
    order,
    validationOptions
  )
  if (paymentError) {
    return {
      isValid: false,
      errorMessage: paymentError.message,
    }
  }

  return {
    isValid: true,
    isAddressComplete,
  }
}

export function validateOrderForSummary(order: OrderData) {
  const validationOptions = {
    allowUnknown: true,
    abortEarly: false,
  }

  switch (order.fulfillment) {
    case "pickup":
      return validatePickupOrder(order, validationOptions)
    case "delivery":
      return validateDeliveryOrderForSummary(order, validationOptions)
    default:
      return {
        isValid: false,
        errorMessage: "Invalid order fulfillment type passed.",
      }
  }
}

export function* selectOrderData(): SagaIterator<any> {
  // GET CURRENT APPLICATION
  const application = yield select((state: RootState) => state.application)

  const settings = yield select((state: RootState) => state.settings.data)

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  // GET CURRENT PAGE
  const page: PageEntity = yield select((state: RootState) =>
    pagesSelector.selectById(state, pageId)
  )

  // GET CURRENT PAGE SESSION
  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )
  // GET GLOBAL CART DATA
  const globalCart: CartState = yield select((state: RootState) => state.cart)
  // GET PAGE CART
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )
  // GET STORE
  const store: StoreEntity = yield select((state: RootState) =>
    storesSelectors.selectById(state, pageCart.storeId)
  )
  // GET PRINT SERVICE
  const printService: PrintServiceEntity = yield select((state: RootState) =>
    printServicesSelector.selectById(state, pageCart.printServiceId)
  )
  // GET PRODUCTS
  const printServiceProducts: PrintServiceProductEntity[] = yield select(
    printServiceProductsSelector.selectAll
  )

  // GET GIFT CERTIFICATES
  const giftCertificates: GiftCertificateEntity[] = yield select(
    giftCertificatesSelectors.selectAll
  )
  // GET LINE ITEMS
  const lineItems: LineItemEntity[] = yield call(getCartLineItems)

  // GET PRODUCT PAGES
  const productPages: ProductPageEntity[] = yield select((state: RootState) =>
    productPagesSelectors.selectAll(state)
  )

  // GET UPLOADS
  const uploads: UploadEntity[] = yield select(uploadsSelectors.selectAll)

  // GET IMAGES
  const images: ImageEntity[] = yield select(imagesSelectors.selectAll)

  const { user, address } = globalCart
  const { payment, fulfillment, couponCode, couponCodeStatus } = pageCart

  // GET TERRITORIES
  const territories = yield select(territoriesSelectors.selectAll)

  // GET order value
  const { orderSummaryId } = pageSession
  const orderSummary = yield select((state: RootState) =>
    orderSummariesSelector.selectById(state, orderSummaryId)
  )
  const isFree = orderSummary.data.totalFloat === 0
  const isPendingAddress = orderSummary.isPendingAddress
  const nonce = pageCart.nonce

  return {
    application,
    settings,
    page,
    fulfillment,
    store,
    payment,
    user,
    address,
    lineItems,
    productPages,
    uploads,
    images,
    printService,
    printServiceProducts,
    couponCode,
    couponCodeStatus,
    territories,
    isFree,
    isPendingAddress,
    giftCertificates,
    nonce,
  }
}

function* lineItemRequiresSVG(lineItem: LineItemEntity): SagaIterator<boolean> {
  if (lineItem.isTemplate) {
    return true
  }

  const productPages: ProductPageEntity[] = yield select((state: RootState) =>
    productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )

  const anyTextRegion = productPages.find(
    productPage => productPage.textRegionIds.length > 0
  )

  if (anyTextRegion) {
    return true
  }

  // loop product pages
  for (let j = 0; j < productPages.length; j++) {
    const currentProductPage = productPages[j]

    const imageRegions: ImageRegionEntity[] = yield select((state: RootState) =>
      imageRegionsSelectors.selectByIds(
        state,
        currentProductPage.imageRegionIds
      )
    )

    const currentImageRegion = imageRegions[0]

    // no image region, this is either a cover or a templage page
    // with no image upload, this requires SVG
    if (!currentImageRegion) {
      return true
    }

    const upload: UploadEntity = yield select((state: RootState) =>
      uploadsSelectors.selectById(state, currentImageRegion.uploadId!)
    )

    // may not have image yet
    if (upload) {
      const currentImage: ImageEntity = yield select((state: RootState) =>
        imagesSelectors.selectById(state, upload.imageId)
      )
      const currentImageTransform: ImageTransformationEntity = yield select(
        (state: RootState) =>
          imageTransformationsSelectors.selectById(
            state,
            currentImage.imageTransformId
          )
      )
      if (currentImageTransform.dirty) {
        return true
      }
    }
  }

  return false
}

function* generateSVGUploadIdsFromLineItem(
  lineItem: LineItemEntity
): SagaIterator<EntityId[]> {
  const { addOne: addOneUpload } = uploads.actions
  const uploadIds: EntityId[] = []
  const product: PrintServiceProductEntity = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, lineItem.productId)
  )
  const allProductPages: ProductPageEntity[] = yield select(
    (state: RootState) =>
      productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )

  const productPages = allProductPages.filter(page => {
    const shouldSkipPage = PageTypeToSkipForUpload.includes(page.pageType)

    return !shouldSkipPage
  })

  const fonts: TemplateTextFont[] = yield select((state: RootState) =>
    templateTextFontsSelector.selectAll(state)
  )

  for (let j = 0; j < productPages.length; j++) {
    const currentProductPage = productPages[j]

    const textRegions: TextRegionEntity[] = yield select((state: RootState) =>
      textRegionsSelectors.selectByIds(state, currentProductPage.textRegionIds)
    )

    const pageToRender: RendererPage = {
      svg: currentProductPage.svg,
      viewBox: {
        x: 0,
        y: 0,
        width: currentProductPage.width,
        height: currentProductPage.height,
      },
      imageRegions: [],
      textRegions,
      fonts: uniq(
        // get unique fonts
        textRegions.map(({ font }) => font)
      )
        .map(
          // map them to client font definitions
          fontName => fonts.find(font => font.name === fontName)
        )
        .filter(
          // remove any that aren't in the client font definitions
          font => font !== undefined
        ) as TemplateTextFont[],
    }

    const imageRegions: ImageRegionEntity[] = yield select((state: RootState) =>
      imageRegionsSelectors.selectByIds(
        state,
        currentProductPage.imageRegionIds
      )
    )

    for (let k = 0; k < imageRegions.length; k++) {
      const currentImageRegion = imageRegions[k]
      const upload: UploadEntity = yield select((state: RootState) =>
        uploadsSelectors.selectById(state, currentImageRegion.uploadId!)
      )
      // may not have image yet
      if (upload) {
        const currentImage: ImageEntity = yield select((state: RootState) =>
          imagesSelectors.selectById(state, upload.imageId)
        )
        const currentImageTransform: ImageTransformationEntity = yield select(
          (state: RootState) =>
            imageTransformationsSelectors.selectById(
              state,
              currentImage.imageTransformId
            )
        )

        pageToRender.imageRegions.push({
          window: currentImageRegion.window,
          image: {
            externalUrl: currentImage.externalUrl,
            width: currentImage.width,
            height: currentImage.height,
            rotation: currentImageTransform.rotation,
            translation: currentImageTransform.translation,
            zoom: currentImageTransform.zoom,
          },
        })
      }
    }

    const isAFoldedCard = Boolean(product.metaData?.card?.folded)

    let svg = ""
    if (isAFoldedCard) {
      svg = renderToString(
        React.createElement(ProductPageFoldedCardRenderer, {
          page: pageToRender,
          product,
          forEditorDisplay: false,
        })
      )
    } else {
      svg = renderToString(
        React.createElement(ProductPageRenderer, {
          page: pageToRender,
          forEditorDisplay: false,
        })
      )
    }

    const newUploadId = nanoid()
    const blob = new Blob([svg], { type: "image/svg+xml" })
    const file = new File([blob], `${newUploadId}.svg`, {
      type: "image/svg+xml",
    })

    const imageId = yield call(createImage, { file })

    yield put(
      addOneUpload({
        id: newUploadId,
        imageId: imageId,
        name: file.name,
        hash: "",
        type: file.type,
        size: file.size,
        progress: 0,
        status: "queued",
        isReady: false,
        hasFailed: false,
        error: null,
      })
    )

    const UPLOAD_RETRY_COUNT = 3
    const UPLOAD_RETRY_DELAY = 2 * 1000 // ms

    // upload image
    yield retry(UPLOAD_RETRY_COUNT, UPLOAD_RETRY_DELAY, processUploadToS3, {
      id: newUploadId,
      file,
    })

    //update image with new upload
    const newUpload: UploadEntity = yield select((state: RootState) =>
      uploadsSelectors.selectById(state, newUploadId)
    )
    yield put(
      images.actions.updateOne({
        id: newUpload.imageId,
        changes: {
          externalUrl: newUpload.location,
          key: newUpload.key,
          filename: newUpload.name,
        },
      })
    )

    uploadIds.push(newUploadId)
  }

  return uploadIds
}

function* generateDefaultUploadIdsFromLineItem(
  lineItem: LineItemEntity
): SagaIterator<EntityId[]> {
  const uploadIds: EntityId[] = []

  const productPages: ProductPageEntity[] = yield select((state: RootState) =>
    productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )

  for (let j = 0; j < productPages.length; j++) {
    const currentProductPage = productPages[j]

    const imageRegions: ImageRegionEntity[] = yield select((state: RootState) =>
      imageRegionsSelectors.selectByIds(
        state,
        currentProductPage.imageRegionIds
      )
    )

    for (let k = 0; k < imageRegions.length; k++) {
      const currentImageRegion = imageRegions[k]
      // may not have image yet
      if (currentImageRegion.uploadId) {
        uploadIds.push(currentImageRegion.uploadId)
      }
    }
  }

  return uploadIds
}

function* uploadLineItemTransformedImageToPrinticular(
  lineItem: LineItemEntity
): SagaIterator {
  try {
    const { updateOne: updateOneLineItem } = lineItemSlice.actions
    const product: PrintServiceProductEntity = yield select(
      (state: RootState) =>
        printServiceProductsSelector.selectById(state, lineItem.productId)
    )

    const imageFit = product?.metaData?.web?.editor?.imageFit

    const isRequireRenderSVG = imageFit === "fit" || imageFit === "none"

    const requiresSVG = yield call(lineItemRequiresSVG, lineItem)

    //always render SVG for photo books
    const renderedUploadIds =
      requiresSVG || isRequireRenderSVG
        ? yield call(generateSVGUploadIdsFromLineItem, lineItem)
        : yield call(generateDefaultUploadIdsFromLineItem, lineItem)

    yield put(
      updateOneLineItem({
        id: lineItem.id,
        changes: {
          renderedUploadIds,
        },
      })
    )
  } catch (error) {
    console.error("Unable to recover from upload")
    console.error(error)
  }
}

function* uploadLineItemTransformedImagesToPrinticular(): SagaIterator {
  // generate images and send to printicular server
  const lineItems: LineItemEntity[] = yield call(getCartLineItems)

  const lineItemChunks = chunk(lineItems, 2)
  for (let i = 0; i < lineItemChunks.length; i++) {
    const lineItemChunk = lineItemChunks[i]
    yield all(
      lineItemChunk.map(lineItem =>
        call(uploadLineItemTransformedImageToPrinticular, lineItem)
      )
    )
    yield delay(250)
  }
}

function* getCurrencyForLineItemsByRetailerId(
  lineItems: LineItemEntity[],
  store: StoreEntity | undefined
): SagaIterator<string | undefined> {
  if (!store) {
    return
  }

  const lineItemProducts: PrintServiceProductEntity[] = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectByIds(
        state,
        lineItems.map((lineItem: LineItemEntity) => lineItem.productId)
      )
  )

  const lineItemProductPriceIds = flatten(
    lineItemProducts.map(product => product.prices)
  )

  const lineItemProductPrices: PrintServiceProductPriceEntity[] = yield select(
    (state: RootState) =>
      printServiceProductPricesSelector.selectByIds(
        state,
        lineItemProductPriceIds
      )
  )

  const matchedPrice = lineItemProductPrices.find(
    price => price.retailerId === store.retailerId
  )

  return matchedPrice?.currency
}

//Customised retry XHR call function, more info see: https://redux-saga.js.org/docs/recipes/#retrying-xhr-calls
function* retryPlaceOrder(
  api: PrinticularApi,
  payload: any,
  maxTries: number,
  delayLength: number
): SagaIterator<PrinticularOrder> {
  let counter = maxTries

  while (true) {
    try {
      const response = yield call(order => api.placeOrder(order), payload)
      return response
    } catch (error: any) {
      if (isInternalServerError(error)) {
        counter -= 1
        if (counter <= 0) {
          throw error
        }
        yield delay(delayLength)
      } else {
        throw error
      }
    }
  }
}

export function* sendOrderToPrinticular(
  option: OrderPlacementOption = "dryRun"
): SagaIterator<any> {
  const printicularApi: PrinticularApi = yield call(getApi)

  if (option !== "dryRun") {
    yield call(uploadLineItemTransformedImagesToPrinticular)
  }

  const orderData = yield call(selectOrderData)

  const { application, printService, lineItems, store, fulfillment } = orderData

  //We should retrieve the currency from product price first
  //if not found, we use the default currency from print service
  //app defaultCurrency is the last fallback
  const appCurrency = application.defaultCurrency ?? "USD"
  const fallbackCurrency = printService.defaultCurrency ?? appCurrency
  //if it is a delivery order, we use app default currency
  const currency =
    fulfillment === "delivery"
      ? appCurrency
      : yield call(getCurrencyForLineItemsByRetailerId, lineItems, store)

  const payload = {
    ...orderData,
    currency: currency ?? fallbackCurrency,
    appUrl: window.location.href,
    deviceToken: printicularApi.getDeviceToken(),
  }

  const isGiftCertificate = lineItems.some(
    (lineItem: LineItemEntity) => lineItem.giftCertificateId !== undefined
  )

  if (option === "dryRun" && isGiftCertificate) {
    payload.giftCertificates = [
      {
        email: "test@example.com",
        name: "Dry Run",
        message: "dry run",
      },
    ]
  }

  const serializedPayload = PrinticularSerializer.serializeOrder(payload)

  switch (option) {
    case "dryRun": {
      // amend payload for dry run, required for product with variable
      // page count, e.g. photo books
      const dryRunSerializedPayload =
        PrinticularSerializer.addFakeImagesForDryRun(serializedPayload, payload)

      const printicularOrder = yield call(
        order => printicularApi.dryRunOrder(order),
        dryRunSerializedPayload
      )
      return printicularOrder as PrinticularOrder
    }

    case "createOrder": {
      const printicularOrder = yield call(
        order => printicularApi.createOrder(order),
        serializedPayload
      )
      return printicularOrder as PrinticularOrder
    }

    case "placeOrder": {
      const RETRY_COUNT = 3
      const RETRY_DELAY = 3 * 1000 // 3 seconds

      const printicularOrder = yield call(
        retryPlaceOrder,
        printicularApi,
        serializedPayload,
        RETRY_COUNT,
        RETRY_DELAY
      )

      return printicularOrder as PrinticularOrder
    }
  }
}

export function* canSupportMultipleOrientations(
  lineItemId: EntityId
): SagaIterator {
  const lineItem = yield call(getLineItem, lineItemId)
  const { hasOverlay, hasFrame, productImages } = yield call(
    getLineItemFrame,
    lineItemId
  )

  const product: PrintServiceProductEntity = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, lineItem.productId)
  )

  if (lineItem.isTemplate || product.metaData?.orientationOverride) {
    return false
  }

  if (hasOverlay || hasFrame) {
    const frameImages = productImages.filter(
      (productImage: PrintServiceProductImageEntity) =>
        productImage?.metaData?.frame
    )
    return frameImages.length > 1
  }

  // all other cases, we can change orientation on single print or canvas
  return true
}

export function* saveFulfillmentDataToLocalStorage(payload: {
  fulfillment: FulfillmentTypes
}): SagaIterator<FulfillmentTypes> {
  const { fulfillment } = payload
  const localStoragePrefix: string = yield select(
    (state: RootState) => state.settings.data.localStoragePrefix
  )

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const pageFulfillement = {
    [currentPageId]: fulfillment,
  }

  const storageKey = `${localStoragePrefix}_fulfillment`
  setLocalStorageItem(storageKey, {
    ...getLocalStorageItem(storageKey, pageFulfillement),
    ...pageFulfillement,
  })

  return fulfillment
}

export function* saveCheckoutDataToLocalStorage(): SagaIterator {
  const localStoragePrefix: string = yield select(
    (state: RootState) => state.settings.data.localStoragePrefix
  )
  const fullPrefix = `${localStoragePrefix}_checkout`

  // GET CURRENT PAGE
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )

  // GET GLOBAL CART DATA
  const globalCart: CartState = yield select((state: RootState) => state.cart)

  // GET PAGE CART
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )

  const { user, address } = globalCart
  const { storePlace, storeId, fulfillment, storeLat, storeLng } = pageCart

  const keyCart = `${fullPrefix}_cart`
  const keyUser = `${fullPrefix}_user`
  const keyAddress = `${fullPrefix}_address`

  setLocalStorageItem(keyCart, {
    ...getLocalStorageItem(keyCart, {}),
    storePlace,
    storeId,
    fulfillment,
    storeLat,
    storeLng,
  })

  setLocalStorageItem(keyUser, {
    ...getLocalStorageItem(keyUser, {}),
    ...user,
  })

  setLocalStorageItem(keyAddress, {
    ...getLocalStorageItem(keyAddress, {}),
    ...address,
  })
}

export function* getNextActionableBlock(payload: {
  pageId: EntityId
  currentBlock: BlockType
}): SagaIterator<string> {
  const { pageId, currentBlock } = payload
  const page: PageEntity = yield call(getPage, { pageId })
  const blocks = yield call(getBlocks, { ids: page.blocks })
  const sortedBlocks = orderBy(blocks, ["order"], ["asc"])
  const currentBlockIndex = sortedBlocks.findIndex(
    block => block.type === currentBlock
  )
  const nextActionableBlock = sortedBlocks.find(
    (b, i) => i > currentBlockIndex && actionableToBlocks.includes(b.type)
  )

  const nextBlockType = nextActionableBlock?.type!

  return nextBlockType
}
