import { replace } from "@wordpress/shortcode"
import React from "react"
import SocialMediaSharer from "~/components/short-codes/SocialMediaSharer"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("shareOnSocial", html, args => {
    return renderShortCode({
      type: "shareOnSocial",
      args,
      component: <SocialMediaSharer config={state.appConfig!} />,
    })
  })
}
