import { EntityId } from "@jackfruit/common"
import { atom, atomFamily } from "recoil"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { Point } from "~/services/cropHelpers"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { getCachedStore } from "~/redux/store"

const getImageTransformById = (id: EntityId): ImageTransformationEntity => {
  const imageTransform =
    getCachedStore().getState().imageTransformations.entities[id]

  if (imageTransform) {
    return imageTransform
  }

  return {
    id: -1,
    rotation: 0,
    zoom: 1,
    translation: { x: 0, y: 0 },
    dirty: false,
    maxZoom: 1,
    minZoom: 1,
    zoomStep: 0.1,
  }
}

// Page Selection
export const regionActivation = atomFamily<
  boolean,
  { type?: "image" | "text"; id: EntityId }
>({
  key: "regionActivation",
  default: false,
})

export const selectedRegion = atom<{ type?: "image" | "text"; id: EntityId }>({
  key: "selectedRegion",
  default: { id: 0 },
})

// Editor scrolling position
export const editorScrollPosition = atom({
  key: "editorScrollPosition",
  default: 0,
})

// Product clamping
export const editorCurrentProductId = atom<EntityId>({
  key: "editorCurrentProductId",
  default: -1,
})

// Image Transformations
export const imageTransformationZoomStateFamily = atomFamily<number, EntityId>({
  key: "imageTransformationZoom",
  default: id => {
    const imageTransform = getImageTransformById(id)
    return imageTransform.zoom
  },
})

export const imageTransformationRotationStateFamily = atomFamily<
  number,
  EntityId
>({
  key: "imageTransformationRotation",
  default: id => {
    const imageTransform = getImageTransformById(id)
    return imageTransform.rotation
  },
})

export const imageTransformationTranslationStateFamily = atomFamily<
  Point,
  EntityId
>({
  key: "imageTransformationTranslation",
  default: id => {
    const imageTransform = getImageTransformById(id)
    return { x: imageTransform.translation.x, y: imageTransform.translation.y }
  },
})

export const imageTransformationMinZoomStateFamily = atomFamily<
  number,
  EntityId
>({
  key: "imageTransformationMinZoom",
  default: id => {
    const imageTransform = getImageTransformById(id)
    return imageTransform.minZoom
  },
})

export const textRegionAlignFamily = atomFamily<
  TextRegionEntity["align"],
  EntityId
>({
  key: "textRegionAlign",
  default: "left",
})

export const textRegionColorFamily = atomFamily<
  TextRegionEntity["color"],
  EntityId
>({
  key: "textRegionColor",
  default: "",
})

export const textRegionFontFamily = atomFamily<
  TextRegionEntity["font"],
  EntityId
>({
  key: "textRegionFont",
  default: "",
})

export const textRegionKeyFamily = atomFamily<
  TextRegionEntity["key"],
  EntityId
>({
  key: "textRegionKey",
  default: "",
})

export const textRegionTextFamily = atomFamily<
  TextRegionEntity["text"],
  EntityId
>({
  key: "textRegionText",
  default: "",
})

export const textRegionSizeFamily = atomFamily<
  TextRegionEntity["size"],
  EntityId
>({
  key: "textRegionSize",
  default: 0.5,
})

export const isMultiImageRegionHovered = atomFamily<boolean, EntityId>({
  key: "isMultiImageRegionHovered",
  default: false,
})

export const photoBookPageIdsFamily = atomFamily<EntityId[], EntityId>({
  key: "photoBookPageIds",
  default: [],
})
