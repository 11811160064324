import { replace } from "@wordpress/shortcode"
import React from "react"
import Accordion from "~/components/short-codes/Accordion"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

// Usage:
// [accordion width="100%" allowMultiple=1 expandedStyle='{"bg": "tomato", "color": "white"}']
//   [item title="This is a title 🚀"]<div>Some HTML goes here.</div>[/item]
//   [item title="This title is expanded 1 🎉" expanded=1]<div>Some other HTML goes here.</div>[/item]
//   [item title="This title is expanded 2 🎉" expanded=1]<div>Some other HTML goes here.</div>[/item]
// [/accordion]
//
// Styles:
// #banner .s__accordion-button {
//   padding: 16px;
// }

// #banner .s__accordion-title {
//   text-align: left;
//   font-size: 24px;
// }

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("accordion", html, args => {
    const {
      id = "1",
      width = "300px",
      allowmultiple = 1,
      expandedstyle = "{}",
    } = args.attrs.named

    const content = args.content ?? ""

    const accordionItems = accordionItemParser(content)

    if (accordionItems.length === 0) {
      return `[accordion error="no items provided"]`
    }

    const allowMultiple = Number(allowmultiple) === 1

    const expandedStyle = JSON.parse(expandedstyle)
    const defaultExpanded: number[] = []

    accordionItems.forEach((item, index) => {
      if (Number(item.expanded) === 1) {
        defaultExpanded.push(index)
      }
    })

    return renderShortCode({
      type: "accordion",
      args,
      component: (
        <Accordion
          width={width}
          allowMultiple={allowMultiple}
          accordionItems={accordionItems}
          expandedStyle={expandedStyle}
          defaultIndex={defaultExpanded}
          config={state.appConfig!}
        />
      ),
    })
  })
}

const accordionItemParser = (html: string = "") => {
  const all: any[] = []
  replace("item", html, args => {
    const itemObj = {
      ...args.attrs.named,
      content: args?.content || "no content",
    }
    all.push(itemObj)

    return ""
  })

  return all
}
