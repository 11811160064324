// mainly used in saga to split long running saga into multiple tasks
export const yieldToMain = async () => {
  return new Promise(resolve => {
    setTimeout(resolve, 0)
  })
}

// from https://github.com/vercel-labs/await-interaction-response
export function interactionResponse(): Promise<unknown> {
  return new Promise(resolve => {
    setTimeout(resolve, 100) // Fallback for the case where the animation frame never fires.
    requestAnimationFrame(() => {
      setTimeout(resolve, 0)
    })
  })
}
