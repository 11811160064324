import { Icon, Link, LinkProps } from "@chakra-ui/react"
import React from "react"

interface Props extends LinkProps {
  icon: any
  link: string
  hover?: any
  size?: string
}

const SocialMediaLink: React.FC<Props> = ({
  icon,
  link,
  hover,
  size = "100%",
  ...props
}) => {
  return (
    <Link
      href={link}
      isExternal
      display="flex"
      rel="noopener"
      justifyContent="center"
      alignItems="center"
      borderRadius={50}
      color="gray.600"
      w={5}
      h={5}
      transition="all ease .45s"
      _hover={hover}
      {...props}
    >
      <Icon as={icon} w={size} h={size} />
    </Link>
  )
}

export default SocialMediaLink
