import { ChakraProvider } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import AppStoreButtons from "~/components/common/AppStoreButtons"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("appButtons", html, args => {
    const justify = args.attrs.named.justify ?? "left"
    if (!state.appConfig) {
      return `[appButtons error="appConfig not found"]`
    }

    const theme = makeTheme(state.appConfig!)

    return renderShortCode({
      type: "appButtons",
      args,
      component: (
        <ChakraProvider theme={theme}>
          <AppStoreButtons
            justify={justify}
            direction="column"
            appStoreUrl={state.appConfig.iosStoreUrl}
            googlePlayUrl={state.appConfig.androidStoreUrl}
            language={state.appConfig.language}
          />
        </ChakraProvider>
      ),
    })
  })
}
