import { EntityId } from "@jackfruit/common"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { call, put, take, takeEvery } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductSwapMap } from "~/interfaces/entities/ProductSwapMap"
import { StoreEntity } from "~/interfaces/entities/Store"
import { yieldToMain } from "~/services/webvitals/defer"
import {
  actions,
  ProductCompatibilityDialogPayload,
  UpdateStorePayload,
} from "../process"
import { carts } from "../state/carts"
import { getCurrentCart } from "./cart"
import { getCurrentPage } from "./page"
import { getCurrentPageSession } from "./pageSession"
import {
  applyProductSwapMap,
  checkProductsCompatibility,
  computeProductSwapMapToTargetedStore,
} from "./processProductsCompatibility"
import { getStore } from "./store"

export default function* watchProcessUpdateStore() {
  yield takeEvery(actions.updateStore.type, processUpdateStore)
}

function* processUpdateStore(
  action: PayloadAction<UpdateStorePayload>
): SagaIterator {
  const { storeId, onClose, onOpen } = action.payload
  const pageSession: PageSessionEntity = yield call(getCurrentPageSession)

  if (pageSession.pageFlow === "product-first") {
    yield call(productFirstFlow, { storeId })
  } else if (pageSession.pageFlow === "store-first") {
    yield call(storeFirstFlow, { storeId, onClose, onOpen })
  }
}

function* productFirstFlow(payload: { storeId: EntityId }): SagaIterator {
  const { storeId } = payload
  const cart: CartEntity = yield call(getCurrentCart)
  yield put(
    carts.actions.updateOne({
      id: cart.id,
      changes: {
        storeId,
      },
    })
  )

  yield call(yieldToMain)

  yield put(
    actions.updateOrderSummary({
      reason: "updated store",
      reasonType: "storeChanged",
    })
  )
}

function* storeFirstFlow(payload: UpdateStorePayload): SagaIterator {
  const { storeId, onOpen, onClose } = payload
  const page = yield call(getCurrentPage)

  const productSwapMap: ProductSwapMap = yield call(
    computeProductSwapMapToTargetedStore,
    { toStoreId: storeId }
  )

  const {
    lineItemsToKeep,
    lineItemsToRemove,
  }: {
    lineItemsToKeep: EntityId[]
    lineItemsToRemove: EntityId[]
  } = yield call(checkProductsCompatibility, {
    productSwapMap,
  })

  let confirm = true
  if (lineItemsToRemove.length > 0) {
    onOpen?.({ lineItemsToKeep, lineItemsToRemove })
    const {
      payload: { choice },
    }: PayloadAction<ProductCompatibilityDialogPayload> = yield take(
      actions.productCompatibilityDialog.type
    )
    onClose?.()
    confirm = choice
  }

  if (confirm) {
    const cart: CartEntity = yield call(getCurrentCart)
    const store: StoreEntity = yield call(getStore, { storeId })

    // check if current coupon is still valid
    if (
      cart.printServiceId !== store.printServiceId &&
      Boolean(cart.couponCode)
    ) {
      // clear coupon as we are not using the same print service anymore
      yield put(
        carts.actions.updateOne({
          id: cart.id,
          changes: {
            couponCode: "",
          },
        })
      )
      yield put(actions.applyCouponCode({}))
    }

    yield put(
      carts.actions.updateOne({
        id: cart.id,
        changes: {
          storeId,
          printServiceId: store.printServiceId,
        },
      })
    )

    yield call(applyProductSwapMap, { productSwapMap })
    yield put(actions.updatedStoreForPage(page.id)())
    yield put(
      actions.updateOrderSummary({
        reason: "store first flow processed",
        reasonType: "other",
      })
    )
  }
}
