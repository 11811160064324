import { SagaIterator } from "@redux-saga/types"
import { nanoid, PayloadAction } from "@reduxjs/toolkit"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { actions, UpdateImageRegionImage } from "../process"
import { imageRegions, imageRegionsSelectors } from "../state/imageRegions"
import { uploads } from "../state/uploads"
import { RootState } from "../store"
import { createImage, setImageTransformation } from "./images"
import { attachUploadToPageSession } from "./pageSession"

export default function* watchUpdateImageRegionImage() {
  yield takeEvery(
    actions.updateImageRegionImage.type,
    processUpdateImageRegionImage
  )
}

function* processUpdateImageRegionImage(
  action: PayloadAction<UpdateImageRegionImage>
): SagaIterator<any> {
  const {
    imageRegionId,
    file,
    imageFit = "fill",
    isClampingEnabled = true,
  } = action.payload
  const { addOne: addOneUpload } = uploads.actions
  const { updateOne: updateOneImageRegion } = imageRegions.actions

  const uploadId = nanoid()

  const imageRegion: ImageRegionEntity = yield select((state: RootState) =>
    imageRegionsSelectors.selectById(state, imageRegionId)
  )

  // create new upload and image
  const imageId = yield call(createImage, { file })

  yield call(setImageTransformation, {
    imageId: imageId,
    window: imageRegion.window,
    imageFit,
    isClampingEnabled,
  })

  yield put(
    addOneUpload({
      id: uploadId,
      imageId: imageId,
      name: file.name,
      hash: "",
      type: file.type,
      size: file.size,
      progress: 0,
      status: "queued",
      isReady: false,
      hasFailed: false,
      error: null,
    })
  )

  // attach new file to line item
  yield put(
    updateOneImageRegion({
      id: imageRegion.id,
      changes: {
        uploadId,
      },
    })
  )

  // attach upload to page session
  yield call(attachUploadToPageSession, uploadId)

  // queue upload for processing
  yield put(
    actions.queueImageForProcessing({
      id: uploadId,
      file: file,
    })
  )
}
