import { replace } from "@wordpress/shortcode"
import React from "react"
import CountrySelector from "~/components/short-codes/CountrySelector"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("countrySelect", html, args => {
    const { default: defaultValue = "", options = "{}" } = args.attrs.named
    const parsedOptions = JSON.parse(options)

    return renderShortCode({
      type: "countrySelect",
      args,
      component: (
        <CountrySelector
          value={defaultValue}
          options={parsedOptions}
          config={state.appConfig!}
        />
      ),
    })
  })
}
