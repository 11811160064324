import {
  FulfillmentTypes,
  PrinticularOrder,
  PrintServiceProductEntity,
  PrintServiceProductPriceEntity,
} from "@jackfruit/common"
import { StoreEntity } from "~/interfaces/entities/Store"

const isDev = process.env.GATSBY_ACTIVE_ENV === "dev"

export type GA4EventType =
  | "add_to_cart"
  | "remove_from_cart"
  | "begin_checkout"
  | "purchase"
  | "experiment_viewed"
  | "editor_cancel_button_click"

interface LineItemPayload {
  item_id: string
  item_name: string
  item_category: string
  item_category2?: string // TODO check with product team
  item_category3: string
  item_variant: string
  price: number // line item total
  quantity: number
}

interface AddToCartPayload {
  event: "add_to_cart"
  ecommerce: {
    value: number
    currency: string
    items: LineItemPayload[]
  }
}

interface RemoveFromCartPayload {
  event: "remove_from_cart"
  ecommerce: {
    value: number
    currency: string
    items: LineItemPayload[]
  }
}

interface BeginCheckoutPayload {
  event: "begin_checkout"
  ecommerce: {
    value: number
    currency: string
    coupon?: string
    items: LineItemPayload[]
  }
}

interface PurchasePayload {
  event: "purchase"
  ecommerce: {
    transaction_id: string
    value: number
    tax: number
    shipping: number
    currency: string
    coupon?: string
    items: LineItemPayload[]
  }
}

interface CouponAppliedPayload {
  event: "coupon_apply"
  label: string // fulfillment
  value: number // orderValue
  couponCode: string
}

interface CouponInvalidPayload {
  event: "coupon_invalid"
  label: string // fulfillment
  value: number // orderValue
  couponCode: string
}

interface ABTestPayload {
  abTest: string
}

interface GA4AbandonmentRatePayload {
  filledProductPagesCount: number
  productName: string
  productCategoryName: string
  fulfillment: FulfillmentTypes
  printServiceName: string
}

interface SetVariablePayload {
  [key: string]: string | number
}

type GA4Payload =
  | AddToCartPayload
  | RemoveFromCartPayload
  | BeginCheckoutPayload
  | PurchasePayload
  | CouponAppliedPayload
  | CouponInvalidPayload
  | ABTestPayload
  | SetVariablePayload

const push = (data: GA4Payload) => {
  if (!window.dataLayer) {
    console.error("GTM is not configured correctly, please check dataLayer")
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: null,
    })
    window.dataLayer.push(data)
  }
}

const GA4SetVariables = (data: SetVariablePayload) => {
  push(data)
}

const GA4fireAbTestAttribute = (
  abTest: string,
  experimentId: string,
  variationId: number
) => {
  push({ abTest })

  if (experimentId && variationId !== null) {
    push({
      event: "experiment_viewed",
      AP_EXPERIMENT_ID: experimentId,
      AP_EXPERIMENT_VARIABLE_ID: variationId,
    })
  }
}

const GA4AddToCart = (
  product: PrintServiceProductEntity,
  price: PrintServiceProductPriceEntity,
  quantity: number = 1
) => {
  const totalItemsValue = quantity * price.total

  const item = {
    item_id: product.productCode,
    item_name: product.name,
    item_category: product.categoryName,
    // item_category2: parentCategorySlug,
    item_category3: product.size,
    item_variant: product.finish,
    price: price.total,
    quantity,
  }

  const payload: AddToCartPayload = {
    event: "add_to_cart",
    ecommerce: {
      value: totalItemsValue,
      currency: price.currency,
      items: [item],
    },
  }

  push(payload)
}

const GA4RemoveFromCart = (
  product: PrintServiceProductEntity,
  price: PrintServiceProductPriceEntity,
  quantity: number = 1
) => {
  const totalItemsValue = quantity * price.total

  const item = {
    item_id: product.productCode,
    item_name: product.name,
    item_category: product.categoryName,
    // item_category2: parentCategorySlug,
    item_category3: product.size,
    item_variant: product.finish,
    price: price.total,
    quantity,
  }

  const payload: RemoveFromCartPayload = {
    event: "remove_from_cart",
    ecommerce: {
      value: totalItemsValue,
      currency: price.currency,
      items: [item],
    },
  }

  push(payload)
}

const GA4BeginCheckout = (order: PrinticularOrder) => {
  const { lineItems } = order

  const payload: BeginCheckoutPayload = {
    event: "begin_checkout",
    ecommerce: {
      currency: order.currency,
      value: order.totalFloat,
      coupon: order.couponCode,
      items: lineItems.map(item => {
        return {
          item_id: item.product.productCode,
          item_name: item.product.name,
          item_category3: String(item.product.size),
          item_variant: item.product.finish,
          price: item.totalFloat,
          quantity: item.quantity,
        }
      }),
    },
  }

  push(payload)
}

const GA4Purchase = (order: PrinticularOrder, store?: StoreEntity) => {
  const { lineItems } = order

  GA4SetVariables({
    AP_VAR_ORDER_ID: String(order.id),
    AP_VAR_ORDER_FULFILLMENT: store ? store.name : "Delivery",
    AP_VAR_ORDER_DISCOUNT: order.discount,
    AP_VAR_ORDER_VALUE: order.total,
    AP_VAR_ORDER_CURRENCY: order.currency,
  })

  const payload: PurchasePayload = {
    event: "purchase",
    ecommerce: {
      transaction_id: String(order.id),
      currency: order.currency,
      shipping: order.freightFloat,
      tax: order.taxTotalFloat,
      value: order.totalFloat,
      coupon: order.couponCode,
      items: lineItems.map(item => {
        return {
          item_id: item.product.productCode,
          item_name: item.product.name,
          item_category3: String(item.product.size),
          item_variant: item.product.finish,
          price: item.total,
          quantity: item.quantity,
        }
      }),
    },
  }

  push(payload)
}

const GA4CouponApplied = (order: PrinticularOrder, store?: StoreEntity) => {
  const payload: CouponAppliedPayload = {
    event: "coupon_apply",
    label: store ? store.name : "Delivery",
    value: order.discountFloat,
    couponCode: order.couponCode,
  }

  push(payload)

  // set the coupon variable
  const couponVariable: SetVariablePayload = {
    AP_VAR_ORDER_COUPON_CODE: order.couponCode,
  }

  push(couponVariable)
}

const GA4CouponInvalid = (order: PrinticularOrder, store?: StoreEntity) => {
  const payload: CouponInvalidPayload = {
    event: "coupon_invalid",
    label: store ? store.name : "Delivery",
    value: order.totalFloat,
    couponCode: order.couponCode,
  }

  push(payload)

  // unset the coupon variable
  const couponVariable: SetVariablePayload = {
    AP_VAR_ORDER_COUPON_CODE: "",
  }

  push(couponVariable)
}

const GA4Login = () => {
  push({
    event: "login",
  })
}

const GA4AbandonmentRate = (payload: GA4AbandonmentRatePayload) => {
  const {
    filledProductPagesCount,
    productName,
    fulfillment,
    printServiceName,
    productCategoryName,
  } = payload

  push({
    event: "editor_cancel_button_click",
    AP_VAR_FILLED_PRODUCT_PAGES: filledProductPagesCount,
    AP_VAR_PRODUCT_NAME: productName,
    AP_VAR_ORDER_FULFILLMENT: fulfillment,
    AP_VAR_PRINT_SERVICE_NAME: printServiceName,
    AP_VAR_PRODUCT_CATEGORY_NAME: productCategoryName,
  })
}

export {
  GA4AddToCart,
  GA4RemoveFromCart,
  GA4BeginCheckout,
  GA4Purchase,
  GA4CouponApplied,
  GA4CouponInvalid,
  GA4fireAbTestAttribute,
  GA4SetVariables,
  GA4Login,
  GA4AbandonmentRate,
}
