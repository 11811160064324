import { TemplateTextFont } from "@jackfruit/common"
import React, { useMemo } from "react"
import { ViewBox } from "~/interfaces/editors/viewbox"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { Point } from "~/services/cropHelpers"
import { sanitizeTemplate } from "~/services/SvgUtils"
import { StaticTextRegionPreview } from "./TextRegionPreview"

export interface Image {
  externalUrl: string
  width: number
  height: number
  rotation: number
  translation: Point
  zoom: number
}

export interface ImageRegion {
  window: ViewBox
  image: Image
}

export interface TextRegion {
  window: ViewBox
  align: string
  color: string
  font: string
  text: string
  size: number
  width: number
}

export interface RendererPage {
  svg?: string
  viewBox: ViewBox
  imageRegions: ImageRegion[]
  textRegions: TextRegion[]
  fonts: TemplateTextFont[]
}

interface Props {
  page: RendererPage
  forEditorDisplay?: boolean
}

const ProductPageRenderer: React.FC<Props> = ({
  page,
  forEditorDisplay = true,
}) => {
  const sanitizedSvg = useMemo(() => {
    return sanitizeTemplate(page.svg ?? "")
  }, [page.svg])

  const svg = forEditorDisplay ? sanitizedSvg : page.svg ?? ""

  return (
    <svg
      viewBox={`0 0 ${page.viewBox.width} ${page.viewBox.height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {page.imageRegions.map((imageRegion, i) => {
        const { window, image } = imageRegion
        const { externalUrl, width, height, rotation, translation, zoom } =
          image
        const { x, y } = translation
        const cx = width / 2
        const cy = height / 2
        const r = rotation
        const z = zoom

        return (
          <svg
            key={i}
            x={window.x}
            y={window.y}
            width={window.width}
            height={window.height}
            viewBox={`${0} ${0} ${window.width} ${window.height}`}
          >
            <image
              transform-origin={`${cx}px ${cy}px`}
              transform={`translate(${x}, ${y}) scale(${z}) rotate(${r})`}
              transform-box="fill-box"
              xlinkHref={externalUrl}
            />
          </svg>
        )
      })}
      {page.svg && (
        <g
          width="100%"
          height="100%"
          pointerEvents="none"
          dangerouslySetInnerHTML={{ __html: svg }}
        ></g>
      )}
      {page.textRegions.map((textRegion, i) => {
        const textRegionEntity = textRegion as TextRegionEntity
        return <StaticTextRegionPreview key={i} textRegion={textRegionEntity} />
      })}
    </svg>
  )
}

export default ProductPageRenderer
