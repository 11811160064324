import {
  Flex,
  Icon,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { AdditionalLocaleUrl } from "@jackfruit/common"
import React from "react"
import { TbWorld } from "react-icons/tb"

interface LocaleNavigationLinkProps extends LinkProps {}

const LocaleNavigationLink: React.FC<LocaleNavigationLinkProps> = ({
  ...props
}) => {
  return (
    <Link
      fontSize="sm"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      _hover={{ textDecor: "none" }}
      isExternal
      {...props}
    />
  )
}

interface Props {
  localeUrls: AdditionalLocaleUrl[]
}

const LocaleNavigation: React.FC<Props> = ({ localeUrls }) => {
  const hasMultipleLocales = localeUrls.length > 1
  const hasNoLocales = localeUrls.length === 0

  if (hasNoLocales) {
    return null
  }

  return hasMultipleLocales ? (
    <Menu>
      <Flex w={32} justify="flex-end">
        <MenuButton>
          <Flex align="center">
            <Icon as={TbWorld} h={6} w={6} />
          </Flex>
        </MenuButton>
      </Flex>
      <MenuList py={0} minW={48}>
        {localeUrls.map((locale, index) => {
          const isFirst = index === 0
          const isLast = index === localeUrls.length - 1

          return (
            <MenuItem
              p={0}
              key={index}
              borderTopRadius={isFirst ? 5 : 0}
              borderBottomRadius={isLast ? 5 : 0}
            >
              <LocaleNavigationLink p={3} w="full" h="full" href={locale.url}>
                {locale.displayName}
              </LocaleNavigationLink>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  ) : (
    <LocaleNavigationLink maxW={32} href={localeUrls[0].url}>
      {localeUrls[0].displayName}
    </LocaleNavigationLink>
  )
}

export default LocaleNavigation
