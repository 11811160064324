import { Button } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"
import { getFallbackTextColor } from "../Utils"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("getStarted", html, args => {
    const {
      colors: { primary },
    } = makeTheme(state.appConfig!)

    const { text } = args.attrs.named
    if (!text) {
      return `[getStarted error="text cannot be empty"]`
    }

    // attribute names uses all lower case: scrollto, colorhover, etc.
    const scrollTo = args.attrs.named.scrollto
    const bg = args.attrs.named.bg ?? primary[500]
    const bgHover = args.attrs.named.bghover ?? primary[600]
    const border = args.attrs.named.border ?? "none"
    const borderHover = args.attrs.named.borderhover ?? "none"
    const fontSize = args.attrs.named.fontsize ?? "var(--chakra-fontSizes-xs)"

    const textColorFallback = getFallbackTextColor(primary[500])
    const color = args.attrs.named.color ?? textColorFallback
    const colorHover = args.attrs.named.colorhover ?? textColorFallback

    return renderShortCode({
      type: "getStarted",
      args,
      component: (
        <Button
          className="get-started-button"
          color={color}
          bg={bg}
          fontSize={fontSize}
          border={border}
          _hover={{ bg: bgHover, color: colorHover, border: borderHover }}
          padding="0.5em 1em" // hard coded styles that changes with font size
          borderRadius="0.25em"
          fontWeight={600}
          onClick={() => scrollTo && window.scrollToElement(scrollTo)}
        >
          {text}
        </Button>
      ),
    })
  })
}
