import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  ChakraProvider,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"

interface AccordionItemsProps {
  title: string
  content: string
}
interface Props extends AccordionProps {
  accordionItems: AccordionItemsProps[]
  expandedStyle?: React.CSSProperties
  config: AppEntityHydrated
}

const Accordion: React.FC<Props> = ({
  width,
  allowMultiple,
  accordionItems,
  expandedStyle,
  defaultIndex,
  config,
}) => {
  const theme = makeTheme(config)

  return (
    <ChakraProvider theme={theme}>
      <Box w={width}>
        <ChakraAccordion
          defaultIndex={defaultIndex}
          allowMultiple={allowMultiple}
          className="s__accordion"
        >
          {accordionItems.map((item, index) => {
            return (
              <AccordionItem key={index} border="none">
                <h2>
                  <AccordionButton
                    pl={0}
                    _expanded={expandedStyle}
                    className="s__accordion-button"
                  >
                    <Box as="span" flex={1} className="s__accordion-title">
                      {item.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pl={0} pb={1} className="s__accordion-panel">
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </ChakraAccordion>
      </Box>
    </ChakraProvider>
  )
}

export default Accordion
