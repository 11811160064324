import {
  AvailableLanguages,
  ClientEntity,
  ClientPrintServiceEntity,
  EntityId,
  PrinticularCategoryEntity,
  PrinticularOrder,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateEntity,
  PrinticularProductTemplateGroupEntity,
  PrinticularProductTemplateGroupPivotEntity,
  PrinticularProductTemplateTagEntity,
  PrintServiceEntity,
  PrintServiceProductCategoryEntity,
  PrintServiceProductCategoryImageEntity,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
  PrintServiceProductPriceEntity,
  RetailerIds,
  TemplateTextColor,
  TemplateTextFont,
} from "@jackfruit/common"
import { flatten } from "lodash"
import qs from "qs"
import { v4 as uuidv4 } from "uuid"
import { AddressLocationEntity } from "~/interfaces/entities/AddressLocation"
import { ApiException } from "~/interfaces/entities/ApiException"
import { AuthResume } from "~/interfaces/entities/AuthResume"
import { AuthUser } from "~/interfaces/entities/AuthUser"
import { AccountWithOrders } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { ContentEntity, ContentType } from "~/interfaces/entities/Content"
import { LatLng } from "~/interfaces/entities/LatLng"
import { StoreEntity, StoreEntityV2 } from "~/interfaces/entities/Store"
import { TemporaryCredentials } from "~/interfaces/entities/TemporaryCredentials"
import { logger } from "~/services/Logger"
import { isOrderAlreadyPlaced } from "./ApiExceptionHelper"
import { PrinticularSerializer } from "./PrinticularSerializer"
import {
  convertToEntities,
  convertToEntity,
  JsonApiResponse,
  JsonApiResponseArray,
} from "./Utils"

const parseError = (error: any): ApiException => {
  if (error?.errors) {
    return {
      code: error.errors[0].code,
      message: error.error?.message ?? error.errors[0].title,
      errors: error.errors,
    }
  }

  if (error.error) {
    return {
      code: error.error.code,
      message: error.error.message,
    }
  }

  if (error.message) {
    return {
      code: "Unspecified",
      message: error.message,
    }
  }

  return {
    code: "Unspecified",
    message: JSON.stringify(error),
  }
}

type QueryStringParam =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | { [key: string]: QueryStringParam }

export interface GetStoreAutocompleteParams {
  filter: {
    text: string
    country: string // USA,PRI
    printServices: string // 2,3
    productIds?: string // 123,234
    radius: number // 100
    storeLimit: number // 6
    locationLimit: number // 10
    retailerIds?: string // 1,2,3
  }
}

interface PrinticularStore {
  attributes: StoreEntityV2
  id: string
  relationships: {
    territory: {
      data: {
        id: string
        type: "Territory"
      }
    }
  }
}
interface PrinticularTerritory {
  type: "Territory"
  id: string
  attributes: {
    countryCode: string
    countryName: string
  }
}

interface StoreSearchResponse {
  data: PrinticularStore[]
  included: PrinticularTerritory[]
}

interface SimplyBluUpdateCardTokenData {
  data: {
    type: "SimplyBluUpdateCardToken"
    attributes: {
      cardToken: string
      timeZone: string
    }
    relationships: {
      printService: {
        data: {
          type: "PrintService"
          id: number
        }
      }
    }
  }
}

interface SimplyBluUpdateCardTokenResponse {
  data: {
    type: "SimplyBluUpdateCardTokenResponse"
    attributes: {
      redirectHtml: string
    }
  }
}

export class PrinticularApi {
  private accessToken: string
  private deviceToken: string
  private baseUrl: string
  private headers: any
  private language: AvailableLanguages

  constructor(
    baseUrl: string,
    accessToken: string,
    deviceToken: string,
    language: AvailableLanguages = "en-US"
  ) {
    this.baseUrl = `${baseUrl}`
    this.accessToken = accessToken
    this.deviceToken = deviceToken
    this.language = language
  }

  public setDeviceToken(deviceToken: string) {
    this.deviceToken = deviceToken
  }

  public getDeviceToken() {
    return this.deviceToken
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  private async get<T = any>(url: string) {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }
      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "GET",
      })

      if (response.status !== 200) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }

      const data = await response.json()

      return data as T
    } catch (error) {
      throw error
    }
  }

  private async post<T = any>(url: string, params: any) {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }

      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "POST",
        body: JSON.stringify(params),
      })

      if ([200, 201, 204].indexOf(response.status) === -1) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }
      const data = await response.json()

      return data as T
    } catch (error) {
      throw error
    }
  }

  private queryString(
    params: { [key: string]: QueryStringParam },
    prefix = "?"
  ): string {
    let query = []
    for (const key in params) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          // Array of string/numbers, convert to comma separated string
          query.push(
            encodeURIComponent(key) +
              "=" +
              (params[key] as string[] | number[]).join(",")
          )
        } else if (typeof params[key] === "object") {
          // Nested object, convert to square bracket notation
          const p = params[key] as { [key: string]: QueryStringParam }
          for (const subKey in p) {
            const obj: QueryStringParam = {}
            obj[`${key}[${subKey}]`] = p[subKey]
            query.push(this.queryString(obj, ""))
          }
        } else {
          // Simple string/number/boolean, just add it
          query.push(
            encodeURIComponent(key) +
              "=" +
              encodeURIComponent(params[key] as string | number | boolean)
          )
        }
      }
    }
    if (query.length) {
      return prefix + query.join("&")
    }
    return ""
  }

  public async getPrintServiceDetails(
    printServiceIds: EntityId[],
    countryCode: string
  ): Promise<{
    client: ClientEntity
    printServices: PrintServiceEntity[]
    products: PrintServiceProductEntity[]
    clientPrintServices: ClientPrintServiceEntity[]
    productImages: PrintServiceProductImageEntity[]
    productPrices: PrintServiceProductPriceEntity[]
    productCategories: PrintServiceProductCategoryEntity[]
    colors: TemplateTextColor[]
    fonts: TemplateTextFont[]
  }> {
    const response = await this.get(
      `api/v2/client?filter[countryCode]=${countryCode}&include=Client.templateTextColors,Client.templateTextFonts,Client.clientRegions,Client.clientProducts,ClientRegion.clientRegionPrintServices,ClientRegionPrintService.clientPrintService,ClientPrintService.printService,ClientProduct.product,Product.productImages,Product.prices,Product.printService,Product.category,Category.categoryImages`
    )

    const clientPrintServicesData = response.included.filter(
      (entity: any) =>
        entity.type === "ClientPrintService" &&
        printServiceIds.includes(
          Number(entity.relationships.printService.data.id)
        )
    )

    const printServicesData = response.included.filter(
      (entity: any) =>
        entity.type === "PrintService" &&
        printServiceIds.includes(Number(entity.id))
    )

    const productsData = response.included.filter(
      (entity: any) =>
        entity.type === "Product" &&
        printServiceIds.includes(
          Number(entity.relationships.printService.data.id)
        )
    )

    const productImagesData = response.included.filter(
      (entity: any) =>
        entity.type === "ProductImage" &&
        productsData
          .map((product: any) =>
            product.relationships.productImages?.data.map(
              (image: any) => image.id
            )
          )
          .flat()
          .includes(entity.id)
    )

    const productPricesData = response.included.filter(
      (entity: any) =>
        entity.type === "Price" &&
        productsData
          .map((product: any) =>
            product.relationships.prices?.data.map((price: any) => price.id)
          )
          .flat()
          .includes(entity.id)
    )

    const productCategoriesData = response.included.filter(
      (entity: any) => entity.type === "Category"
    )

    const productCategoryImagesData = response.included.filter(
      (entity: any) => entity.type === "CategoryImage"
    )

    const { templateTextColors: colors, templateTextFonts: fonts } =
      convertToEntities<{
        templateTextColors: TemplateTextColor[]
        templateTextFonts: TemplateTextFont[]
      }>(response)

    const client = convertToEntity<ClientEntity>(response.data)

    const clientPrintServices = clientPrintServicesData.map((service: any) =>
      convertToEntity<ClientPrintServiceEntity>(service)
    )

    const printServices = printServicesData.map((service: any) =>
      convertToEntity<PrintServiceEntity>(service)
    )

    const clientProductsDataForProducts = response.included
      .filter((entity: any) => entity.type === "ClientProduct")
      .reduce((acc: any, current: any) => {
        const productId = current.relationships.product.data.id
        acc[productId] = current.attributes

        return acc
      }, {})

    const products = productsData.map((product: any) => {
      product.attributes.categoryName =
        response.included.find(
          (entity: any) =>
            entity.type === "Category" &&
            product.relationships.category?.data.id === entity.id
        )?.attributes.name ?? ""

      // re apply the position based on the client product sortOrder
      product.attributes.position =
        clientProductsDataForProducts[product.id].sortOrder

      return convertToEntity<PrintServiceProductEntity>(product)
    })

    const productImages = productImagesData.map((image: any) =>
      convertToEntity<PrintServiceProductImageEntity>(image)
    )

    const productPrices = productPricesData.map((price: any) => {
      price.attributes.total = parseFloat(price.attributes.total)
      return convertToEntity<PrintServiceProductPriceEntity>(price)
    })

    const productCategories: any[] = productCategoriesData.map(
      (category: any) =>
        convertToEntity<PrintServiceProductCategoryEntity>(category)
    )

    const productCategoryImages: PrintServiceProductCategoryImageEntity[] =
      productCategoryImagesData.map((image: any) => {
        return convertToEntity<PrintServiceProductCategoryImageEntity>(image)
      })

    productCategories.forEach((category, index, collection) => {
      const images = productCategoryImages.filter(image =>
        category.categoryImages?.includes(image.id)
      )
      // fix missing sort order
      if (category.sortOrder === null) {
        category.sortOrder = 0
      }
      collection[index].images = images
    })

    return {
      client,
      printServices,
      clientPrintServices,
      products,
      productImages,
      productPrices,
      colors,
      fonts,
      productCategories,
    }
  }

  async getAvailableStores(
    latLng: LatLng,
    printServiceId: EntityId,
    printServiceProductCodes: EntityId[],
    retailerIds: RetailerIds
  ): Promise<Partial<StoreEntity>[]> {
    const productCodeList = printServiceProductCodes.sort().join(",")
    const retailers = retailerIds?.sort().join(",")
    const url = `api/v2/client/stores/search`

    if (latLng.lat === undefined || latLng.lng === undefined) {
      throw new Error("Invalid latLng")
    }

    const params: any = {
      "filter[location]": `${latLng.lat},${latLng.lng}`,
      "filter[printService]": printServiceId,
      include: "Store.territory",
    }

    if (productCodeList) {
      params["filter[products]"] = productCodeList
    }

    if (retailers) {
      params["filter[retailers]"] = retailers
    }

    const queryString = Object.keys(params)
      .sort()
      .map(key => key + "=" + params[key])
      .join("&")

    const { data, included } = (await this.get(
      `${url}?${queryString}`
    )) as StoreSearchResponse

    const territories = included?.filter(({ type }) => type === "Territory")

    return data.map(({ attributes, id, relationships: { territory } }) => {
      const foundTerritory = territories?.find(
        ({ id }) => territory.data.id === id
      )
      return {
        id,
        active: attributes.active ? 1 : 0,
        currency: attributes?.currency ?? "USD",
        latitude: `${attributes.latitude}`,
        longitude: `${attributes.longitude}`,
        name: attributes.name,
        note: attributes?.note ?? "",
        phone: attributes.phone,
        products: attributes.productCodes,
        retailerId: attributes.retailerId,
        retailerStoreId: attributes.retailerStoreId,
        address: attributes.storeAddress1,
        addressLine2: attributes?.storeAddress2 ?? "",
        city: attributes.storeCity,
        country: attributes.storeCountry,
        postcode: attributes.storePostCode,
        state: attributes.storeRegion,
        printServiceId: printServiceId as number,
        countryCode: foundTerritory?.attributes?.countryCode ?? "US",
      }
    })
  }

  /**
   *  Get list of available stores for specific coordinates and print services
   */
  async getAvailableStoresForMultiplePrintServices(
    latLng: LatLng,
    printServiceIds: EntityId[],
    printServiceProductCodes: EntityId[],
    retailerIds: RetailerIds
  ): Promise<Partial<StoreEntity>[]> {
    const results = await Promise.all(
      printServiceIds.map(async printServiceId =>
        this.getAvailableStores(
          latLng,
          printServiceId,
          printServiceProductCodes,
          retailerIds
        )
      )
    )

    return flatten(results)
  }

  /**
   * Get remote store details
   */
  public async getStoreDetails({
    remotePrintServiceId,
    remoteStoreId,
  }: {
    remotePrintServiceId: EntityId
    remoteStoreId: EntityId
  }): Promise<StoreEntity> {
    const response = await this.get(
      `api/v2/client/print-services/${remotePrintServiceId}/stores/${remoteStoreId}`
    )

    return convertToEntity<StoreEntity>(response.data)
  }

  /**
   * Get remote content
   */
  public async getContentList({
    contentType,
  }: {
    contentType: ContentType
  }): Promise<ContentEntity[]> {
    const response = await this.get<JsonApiResponseArray>(
      `api/v2/client/content` +
        this.queryString({
          filter: {
            type: contentType,
          },
        })
    )
    return response.data.map(entity => convertToEntity<ContentEntity>(entity))
  }

  public async getContent({ id }: { id: string }): Promise<ContentEntity> {
    const response = await this.get<JsonApiResponse>(
      `api/v2/client/content/${id}`
    )
    return convertToEntity<ContentEntity>(response.data)
  }

  async simplyBluUpdateCardToken(
    cardToken: string,
    printServiceId: number,
    timeZone: string
  ): Promise<string> {
    const data: SimplyBluUpdateCardTokenData = {
      data: {
        type: "SimplyBluUpdateCardToken",
        attributes: {
          cardToken,
          timeZone,
        },
        relationships: {
          printService: {
            data: {
              type: "PrintService",
              id: printServiceId,
            },
          },
        },
      },
    }

    const result = await this.post<SimplyBluUpdateCardTokenResponse>(
      `api/v2/client/simplyblu/update-card-token`,
      data
    )

    return result.data.attributes.redirectHtml
  }

  /**
   * Get list of available server templates
   *
   * @param payload
   *    @param preTags - The initial and complete list of tags for the current block
   *    @param tags - The filtered list of tags (user selection)
   *    @param limit - The pagination limit
   *    @param offset - The pagination offset (page * limit = offset)
   *
   * @returns PrinticularProductTemplateEntity[]
   */
  public async getAvailableProductTemplates(payload: {
    preTags?: EntityId[]
    tags?: EntityId[]
    limit?: number
    offset?: number
    keyword?: string
    printServiceIds?: EntityId[]
    templateTypes?: string[]
  }): Promise<{
    productTemplates: PrinticularProductTemplateEntity[]
    productTemplateCategories: PrinticularProductTemplateCategoryEntity[]
    categories: PrinticularCategoryEntity[]
    totalRemoteProducts: number
  }> {
    const {
      tags = [],
      preTags = [],
      limit = 25,
      offset = 0,
      keyword = "",
      printServiceIds = [],
      templateTypes = [],
    } = payload

    const tagsList = [...tags].sort().join(",")
    const preTagsList = [...preTags].sort().join(",")

    const resource = "api/v2/client/product-templates"
    const params: any = {
      sort: "-sortOrder,-clientTemplateSortOrder",
      "filter[keyword]": keyword,
      "filter[pretags]": preTagsList,
      "filter[tags]": tagsList,
      "page[limit]": limit,
      "page[offset]": offset,
      include:
        "ProductTemplate.productTemplateGroupPivots,ProductTemplateGroupPivot.productTemplateGroup",
    }

    if (templateTypes.length > 0) {
      params["filter[templateType]"] = templateTypes.sort().join(",")
    }

    if (printServiceIds.length > 0) {
      params["filter[printServices]"] = printServiceIds
        .sort((a: EntityId, b: EntityId) => Number(a) - Number(b))
        .join(",")
    }

    const queryString = Object.keys(params)
      .sort()
      .map(key => key + "=" + params[key])
      .join("&")

    const result = await this.get(`${resource}?${queryString}`)

    const { data, meta } = result
    const included = result.included ?? []

    const productTemplatesData = data

    const productTemplates: PrinticularProductTemplateEntity[] =
      productTemplatesData.map((template: any) =>
        convertToEntity<PrinticularProductTemplateEntity>(template)
      )

    const productTemplateCategoriesData = included.filter(
      (entity: any) => entity.type === "ProductTemplateCategory"
    )
    const productTemplateCategories: PrinticularProductTemplateCategoryEntity[] =
      productTemplateCategoriesData.map((productTemplateCategory: any) =>
        convertToEntity<PrinticularProductTemplateCategoryEntity>(
          productTemplateCategory
        )
      )
    const categoriesData = included.filter(
      (entity: any) => entity.type === "Category"
    )
    const categories: PrinticularCategoryEntity[] = categoriesData.map(
      (category: any) => convertToEntity<PrinticularCategoryEntity>(category)
    )

    const templateGroupPivotsData = included.filter(
      (entity: any) => entity.type === "ProductTemplateGroupPivot"
    )

    const templateGroupPivots: PrinticularProductTemplateGroupPivotEntity[] =
      templateGroupPivotsData.map((pivot: any) =>
        convertToEntity<PrinticularProductTemplateGroupPivotEntity>(pivot)
      )

    const productTemplateGroupsData = included.filter(
      (entity: any) => entity.type === "ProductTemplateGroup"
    )

    const templateGroups: PrinticularProductTemplateGroupEntity[] =
      productTemplateGroupsData.map((group: any) =>
        convertToEntity<PrinticularProductTemplateGroupEntity>(group)
      )

    // attach categories details to each template
    // for ease of use
    productTemplates.forEach((template, index, orig) => {
      const templateCategory = productTemplateCategories.find(
        tc => tc.id === template.productTemplateCategories[0]
      )
      const category = categories.find(c => c.id === templateCategory?.category)
      const groupIds = templateGroupPivots
        .filter(pivot => template.productTemplateGroupPivots.includes(pivot.id))
        .map(pivot => pivot.productTemplateGroup)

      const groups = templateGroups.filter(group => groupIds.includes(group.id))

      orig[index].categoryName = category?.name ?? ""
      orig[index].categoryDisplayName = category?.displayName ?? ""
      orig[index].pageCount = template.variants[0].pages.length
      orig[index].templateGroups = groups.map(group => {
        return {
          name: group.name,
          type: group.groupType,
        }
      })
    })

    return {
      productTemplates,
      productTemplateCategories,
      categories,
      totalRemoteProducts: meta.foundRows,
    }
  }

  /**
   * Get remote tags list for a given list of slugs
   * (usefull to collect tags's title to be displayed on filters)
   */
  public async getProductTemplateTags(
    tags: EntityId[] = []
  ): Promise<PrinticularProductTemplateTagEntity[]> {
    const tagList = tags.sort().join(",")
    const url = `api/v2/client/product-templates/tags?filter[tags]=${tagList}`
    const { data } = await this.get<JsonApiResponseArray>(url)

    const entities = data.map(tag =>
      convertToEntity<PrinticularProductTemplateTagEntity>(tag)
    )

    return entities.map((entity: PrinticularProductTemplateTagEntity) => {
      return {
        ...entity,
        isFetchingTemplates: false,
      }
    })
  }

  /**
   * Order placement API V2
   * @param payload
   * @returns PrinticularOrder
   */

  /**
   * Dry run the order on autopilot apiV2
   */
  public async dryRunOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/dryrun?include=Order.printService,Order.address,Order.giftCertificate,Order.giftCertificateTransaction,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`

    //edit the payload for dryrun
    payload.data.attributes = {
      ...payload.data.attributes,
      nonce: uuidv4(),
    }

    const data = await this.post(url, payload)

    PrinticularSerializer.deserializeOrder(data)
    return PrinticularSerializer.deserializeOrder(data)
  }

  /**
   * Create an order on autopilot apiV2 to get the paymentIntents, stripe payment only
   */
  public async createOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/create?include=Order.printService,Order.address,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`
    try {
      const data = await this.post(url, payload)
      return PrinticularSerializer.deserializeOrder(data)
    } catch (error: any) {
      if (isOrderAlreadyPlaced(error)) {
        const fakeOrder: any = {
          id: 1,
          lineItems: [],
          shippingMethods: [],
        }

        return fakeOrder as PrinticularOrder
      } else {
        throw error
      }
    }
  }

  /**
   * Place the order on autopilot apiV2
   */
  public async placeOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/v2/client/orders/place?include=Order.printService,Order.address,Order.lineItems,Order.store,Order.lineItems,Order.printServiceShippingMethod,LineItem.product,LineItem.productTemplate,LineItem.productTemplateVariant`

    try {
      const data = await this.post(url, payload)

      return PrinticularSerializer.deserializeOrder(data)
    } catch (error: any) {
      // in case nonce match with existing order
      // we need to fake an order for the success page
      // to display correctly
      if (isOrderAlreadyPlaced(error)) {
        const fakeOrder: any = {
          id: 1,
          lineItems: [],
          shippingMethods: [],
        }

        return fakeOrder as PrinticularOrder
      } else {
        throw error
      }
    }
  }

  /**
   * Get location suggestions
   */
  public async getStoreAutocomplete(
    params: GetStoreAutocompleteParams
  ): Promise<AddressLocationEntity[]> {
    function alphabeticalSort(a: string, b: string) {
      return a.localeCompare(b)
    }

    const query = qs.stringify(params, { sort: alphabeticalSort }).toString()
    const url = `api/v2/client/stores/autocomplete?${query}`
    const data = await this.get(url)

    return data.data.map((autocomplete: any) =>
      convertToEntity<AddressLocationEntity>(autocomplete)
    )
  }

  /**
   * return temporary credentials to use with aws map provider
   */
  public async getTempCredentials() {
    const url = "api/v2/client/stores/credentials"
    const data = await this.get(url)

    return convertToEntity<TemporaryCredentials>(data.data)
  }

  /**
   * authenticate user
   */
  public async login({ login, password }: { login: string; password: string }) {
    const url = "api/v2/client/account/login"
    const data = await this.post(url, {
      data: {
        type: "AccountLogin",
        attributes: {
          emailAddress: login,
          password: password,
          deviceId: this.getDeviceToken(),
        },
      },
    })

    return convertToEntity<AuthUser>(data.data)
  }

  public async register(payload: { emailAddress: string; siteUrl: string }) {
    const { emailAddress, siteUrl } = payload

    const response = await this.post(`api/v2/client/account/register`, {
      data: {
        type: "AccountRegister",
        attributes: {
          emailAddress,
          deviceId: this.getDeviceToken(),
          resetUrl: new URL("reset-password/", siteUrl).toString(),
          registerUrl: new URL("register/confirm/", siteUrl).toString(),
        },
      },
    })

    return convertToEntity(response.data)
  }

  /**
   * try to login user silently on page refresh
   */
  public async loginSilently({ token }: { token: string }) {
    // force token
    this.accessToken = token
    const authResume = await this.resumeLogin()

    const [userInfos] = authResume.clientUser
    const partialAuthUser: AuthUser = {
      ...userInfos,
      loginToken: authResume.token,
    }

    return partialAuthUser
  }

  /**
   * Refresh user auth session and get his informations
   */
  public async resumeLogin() {
    const response = await this.post(
      `api/v2/client/account/resume?include=ResumeLogin.clientUser`,
      {
        data: {
          type: "ResumeLogin",
          attributes: {
            deviceId: this.getDeviceToken(),
          },
        },
      }
    )

    return convertToEntities<AuthResume>(response)
  }

  public async registerConfirmation(payload: {
    token: string
    password: string
  }) {
    const { password, token } = payload

    const response = await this.post(`api/v2/client/account/register/confirm`, {
      data: {
        type: "AccountConfirm",
        attributes: {
          token,
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async forgotPassword(payload: {
    emailAddress: string
    siteUrl: string
  }) {
    const { emailAddress, siteUrl } = payload

    const response = await this.post(`api/v2/client/account/forgot`, {
      data: {
        type: "AccountForgot",
        attributes: {
          emailAddress,
          deviceId: this.deviceToken,
          resetUrl: new URL("reset-password/", siteUrl).toString(),
        },
      },
    })

    return convertToEntity(response.data)
  }

  /**
   * Get user account details
   */
  public async getUserAccount() {
    const response = await this.get(`api/v2/client/account`)
    return convertToEntity<AuthUser>(response.data)
  }

  /**
   * Get user account orders
   */
  public async getUserAccountOrders(): Promise<AccountWithOrders> {
    const response = await this.get(
      `api/v2/client/account?include=LineItem.originalImage,LineItem.processedImage,Order.suborders,Order.thumbnailImage,Product.productImages`
    )

    return convertToEntities<AccountWithOrders>(response)
  }

  /**
   * Get a user order
   */
  public async getUserOrder(orderId: EntityId): Promise<Order> {
    const response = await this.get(
      `api/v2/client/orders/${orderId}?include=Order.suborders,Product.productImages`
    )

    return convertToEntities<Order>(response)
  }

  /*
   * Update user account details
   */
  public async updateUserAccount(payload: {
    id: EntityId
    name: string
    phoneNumber: string
  }) {
    const { id, name, phoneNumber } = payload

    const response = await this.post(`api/v2/client/account`, {
      data: {
        id,
        type: "ClientUser",
        attributes: {
          name,
          phoneNumber,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async resetPassword(payload: { password: string; token: string }) {
    const { password, token } = payload

    const response = await this.post(`api/v2/client/account/reset`, {
      data: {
        type: "AccountReset",
        attributes: {
          token,
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async changePassword(payload: { password: string }) {
    const { password } = payload

    const response = await this.post(`api/v2/client/account/change-password`, {
      data: {
        type: "AccountChangePassword",
        attributes: {
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async ping() {
    const response = await this.get(`api/v2/ping`)

    return response
  }
}
