import { AppEntityHydrated } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import { useEffectOnce, useSearchParam } from "react-use"
import { useIsAndroidDevice } from "~/hooks/useIsAndroidDevice"
import { useIsAppleDevice } from "~/hooks/useIsAppleDevice"
import AppBannerFull from "./AppBannerFull"
import AppBannerMinimal from "./AppBannerMinimal"
import AppBannerTop from "./AppBannerTop"

interface Props {
  appConfig: AppEntityHydrated
}

enum AppBannerTypes {
  Top = "top",
  Full = "full",
  Minimal = "minimal",
}

const transitionTime = 300

const AppBannerWrapper: React.FC<Props> = ({ appConfig }) => {
  const {
    mobileAppBannerVariant,
    showMobileAppInstallBanner,
    iosStoreUrl,
    androidStoreUrl,
  } = appConfig

  const hideAppInstalBanner = useSearchParam("hideAppInstalBanner") === "1"

  const [isBannerVisible, setIsBannerVisible] = useState(
    Boolean(showMobileAppInstallBanner)
  )

  const [slideIn, setSlideIn] = useState(true)

  const onCloseBanner = useCallback(() => {
    setSlideIn(false)
    setTimeout(() => {
      setIsBannerVisible(false)
    }, transitionTime)
  }, [])

  const isAppleDevice = useIsAppleDevice()
  const isAndroidDevice = useIsAndroidDevice()

  const onInstall = useCallback(() => {
    window.open(isAppleDevice ? iosStoreUrl : androidStoreUrl, "newWindow")
    onCloseBanner()
  }, [androidStoreUrl, iosStoreUrl, isAppleDevice, onCloseBanner])

  useEffectOnce(() => {
    const hasUrlAvailable =
      (isAppleDevice && iosStoreUrl) || (isAndroidDevice && androidStoreUrl)
    const shouldShowMobileAppInstallBanner =
      Boolean(showMobileAppInstallBanner) &&
      Boolean(hasUrlAvailable) &&
      !hideAppInstalBanner
    setIsBannerVisible(shouldShowMobileAppInstallBanner)
  })

  if (!isBannerVisible) {
    return null
  }

  switch (mobileAppBannerVariant) {
    case AppBannerTypes.Full:
      return (
        <AppBannerFull
          appConfig={appConfig}
          onInstall={onInstall}
          onCloseBanner={onCloseBanner}
          isAppleDevice={isAppleDevice}
          slideIn={slideIn}
          transitionTime={transitionTime}
        />
      )
    case AppBannerTypes.Minimal:
      return (
        <AppBannerMinimal
          appConfig={appConfig}
          onInstall={onInstall}
          onCloseBanner={onCloseBanner}
          isAppleDevice={isAppleDevice}
          slideIn={slideIn}
          transitionTime={transitionTime}
        />
      )
  }

  return (
    <AppBannerTop
      appConfig={appConfig}
      onInstall={onInstall}
      onCloseBanner={onCloseBanner}
      slideIn={slideIn}
      transitionTime={transitionTime}
    />
  )
}

export default AppBannerWrapper
